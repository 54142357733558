import Vue from 'vue';
import Vuex from 'vuex';

import common from './modules/common';
import auth from './modules/auth';
import alert from './modules/alert';
import contact from './modules/contact';
import blog from './modules/blog';
import pagina from './modules/pagina';
import user from './modules/user';
import navbar from './modules/navbar';
import footer from './modules/footer';
import document from './modules/document';
import modal from './modules/modal';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    common,
    auth,
    alert,
    contact,
    user,
    document,
    blog,
    pagina,
    modal,
    navbar,
    footer,
  },
  strict: true,
});
