import _ from 'lodash';

const state = {
  showModalLogin: false,
  isCreatingDocument: false,
};

const actions = {
  toggleModalLogin({ commit }, { showModalLogin }) {
    commit('toggleModalLogin', { showModalLogin });
  },
  toggleIsCreatingDocument({ commit }, { isCreatingDocument }) {
    commit('toggleIsCreatingDocument', { isCreatingDocument });
  },
};

const mutations = {
  toggleModalLogin(state, { showModalLogin }) {
    state.showModalLogin = showModalLogin;
  },
  toggleIsCreatingDocument(state, { isCreatingDocument }) {
    state.isCreatingDocument = isCreatingDocument;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
