import Vue from 'vue';

const state = {
  alertType: null,
  alertPosition: null,
  alertTitle: null,
  showAlert: false,
  alertText: null,
};

const actions = {
  success({ commit }, { showType, position, title, text }) {
    commit('success', { showType, position, title, text });
  },
  info({ commit }, { showType, position, title, text }) {
    commit('info', { showType, position, title, text });
  },
  error({ commit }, { showType, position, title, text }) {
    commit('error', { showType, position, title, text });
  },
  setMessage({ commit }, { type, message }) {
    commit('setMessage', { type, message });
  },
  clear({ commit }) {
    commit('clear');
  },
};

const getters = {
  errorMessages: (state) => {
    if (state.type === 'error' && state.text !== '') {
      return state.text;
    }
    return '';
  },
  successMessages: (state) => {
    if (state.type === 'success' && state.text !== '') {
      return state.text;
    }
    return '';
  },
};

const mutations = {
  success(state, { showType, position, title, text }) {
    state.alertType = 'success';
    state.alertTitle = title;
    state.alertText = text;
    state.alertPosition = position;
    state.showAlert = true;
  },
  info(state, { showType, position, title, text }) {
    state.alertType = 'info';
    state.alertTitle = title;
    state.alertText = text;
    state.showAlert = true;
  },
  error(state, { showType, position, title, text }) {
    state.alertType = 'error';
    state.alertTitle = title;
    state.showAlert = true;
    state.alertText = text;
  },
  clear(state) {
    state.alertType = null;
    state.alertPosition = null;
    state.alertTitle = null;
    state.showAlert = false;
    state.alertText = null;
  },
  setMessage(state, { type, message }) {
    state.type = type;
    state.text = message;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
