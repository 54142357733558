<template>
  <div>
    <div class="">
      <div class="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="text-center">
          <h2
            class="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900"
          >
            Um conjunto completo de soluções em um preço único e acessível
          </h2>
          <p class="mt-4 text-xl leading-7 text-gray-500">
            Geramos os documentos na hora e você consegue consultá-los a qualquer momento.
          </p>
        </div>
      </div>
    </div>
    <div class="mt-8 bg-white sm:mt-12">
      <div class="relative">
        <div class="absolute inset-0 h-1/2"></div>
        <div class="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
          <div
            class="max-w-lg mx-auto bg-gray-200 rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex"
          >
            <div class="bg-gray-100 px-6 py-8 lg:flex-shrink-1 lg:p-12">
              <h3
                class="text-2xl leading-8 font-extrabold text-gray-900 sm:text-3xl sm:leading-9"
              >
                Plano LGPD Completo
              </h3>
              <p class="mt-6 text-base leading-6 text-gray-500">
                Todos os documentos necessários para você adequar sua empresa às normas da LGPD. Gere agora mesmo documentos como política de privacidade, termos de uso e mais.
              </p>
              <div class="mt-8">
                <div class="flex items-center">
                  <h4
                    class="flex-shrink-0 pr-4 bg-gray-100 text-sm leading-5 tracking-wider font-semibold uppercase text-indigo-600"
                  >
                    O que está incluso
                  </h4>
                  <div class="flex-1 border-t-2 border-gray-200"></div>
                </div>
                <ul
                  class="mt-8 lg:grid lg:grid-cols-2 lg:col-gap-8 lg:row-gap-5"
                >
                  <li class="flex items-start lg:col-span-1">
                    <div class="flex-shrink-0">
                      <svg
                        class="h-5 w-5 text-green-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <p class="ml-3 text-sm leading-5 text-gray-700">
                      Política de Privacidade
                    </p>
                  </li>
                  <li class="flex items-start lg:col-span-1">
                    <div class="flex-shrink-0">
                      <svg
                        class="h-5 w-5 text-green-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <p class="ml-3 text-sm leading-5 text-gray-700">
                      Relatório de Impacto
                    </p>
                  </li>
                  <li class="mt-5 flex items-start lg:col-span-1 lg:mt-0">
                    <div class="flex-shrink-0">
                      <svg
                        class="h-5 w-5 text-green-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <p class="ml-3 text-sm leading-5 text-gray-700">
                      Planilha de registro de operações
                    </p>
                  </li>
                  <li class="mt-5 flex items-start lg:col-span-1 lg:mt-0">
                    <div class="flex-shrink-0">
                      <svg
                        class="h-5 w-5 text-green-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <p class="ml-3 text-sm leading-5 text-gray-700">
                      Guia de utilização das planilhas
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div
              class="relative py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12"
            >
              <div class="ribbon bg-red-500 text-sm whitespace-no-wrap px-4">
                VIRADA 2021 25%
              </div>

              <p class="text-lg leading-6 font-medium text-gray-900">
                Pague uma vez, acesso instantâneo
              </p>
              <div
                class="mt-4 flex items-center justify-center text-3xl leading-none font-extrabold text-gray-900"
              >
                <span class=""> R$ 399,90 </span>
                <span class="ml-3 text-gray-600 line-through"> R$ 499,90 </span>
                </span>
              </div>
              <p class="mt-4 text-sm leading-5">
                <a href="/termos" class="font-medium text-gray-500 underline">
                  Veja nossos termos de uso
                </a>
              </p>
              <div class="mt-6">
                <div class="rounded-md shadow">
                  <a
                    href="#"
                    class="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-900 hover:bg-gray-800 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                  >
                    Tenha Acesso
                  </a>
                </div>
              </div>
              <div class="mt-4 text-sm leading-5">
                <a href="/contato" class="font-medium text-gray-900">
                  Veja o plano corporativo
                  <span class="font-normal text-gray-500"> (+8 docs) </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pricing',
};
</script>

<style scoped>
.ribbon {
  position: absolute;
  top: 0;
  right: 0;
  width: 180px;
  height: 22px;
  margin-right: -40px;
  margin-top: 35px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Safari prior 9.0 */
  transform: rotate(45deg); /* Standard syntax */
}

  @media (max-width: 600px) {
    .ribbon {
      display: none !important;
    }
  }
</style>
