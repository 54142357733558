import _ from 'lodash';

import blogService from '@/services/blogService';

const state = {
  loading: false,
  error: null,
  posts: {},
  post: {},
};

const actions = {
  getPosts({ commit }, { sistema, count }) {
    blogService
      .getPosts(sistema, count)
      .then((response) => {
        commit('getPostsSuccess', response);
      })
      .catch((e) => {
        commit('getPostsFailure', e);
      });
  },
  getPost({ commit }, { sistema, id }) {
    blogService
      .getPost(sistema, id)
      .then((response) => {
        commit('getPostSuccess', response);
      })
      .catch((e) => {
        commit('getPostFailure', e);
      });
  },
};

const mutations = {
  getPostsSuccess(state, response) {
    state.loading = false;
    state.posts = response;
    state.error = false;
  },
  getPostsFailure(state) {
    state.loading = false;
    state.error = state;
    state.blog = {};
  },
  getPostSuccess(state, response) {
    state.loading = false;
    state.post = response;
    state.error = false;
  },
  getPostFailure(state) {
    state.loading = false;
    state.error = state;
    state.blog = {};
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
