<template>
  <div class="relative bg-gray-50">
    <section
      class="container mx-auto pt-16 pb-0 xs:pt-20 sm:pt-20 md:pt-48 px-4 z-10"
    >
      <main class="mt-8 sm:mt-0 md:mt-10 lg:mt-0 z-10">
        <div class="mx-auto max-w-screen-xl">
          <div class="lg:grid lg:grid-cols-12 lg:gap-8">
            <div class="col-span-3"></div>
            <div class="col-span-6">
              <h2
                class="mt-4 text-center text-xl font-extrabold text-gray-900 sm:mt-5 sm:leading-no leading-none ne sm:text-5xl lg:mt-6 lg:text-3xl xl:text-4xl"
              >
                Receba uma política de privacidade para o seu site em menos de
                dois minutos
              </h2>
              <p class="text-xl leading-3 text-center text-gray-700 mt-6">
                Taxa única, seus para sempre.
              </p>
            </div>
            <div class="col-span-3"></div>
          </div>
        </div>
      </main>
    </section>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'Hero.vue',
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
  },
};
</script>

<style scoped>
.ribbon {
  position: absolute;
  top: 0;
  right: 0;
  width: 200px;
  height: 22px;
  margin-right: -50px;
  margin-top: 30px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Safari prior 9.0 */
  transform: rotate(45deg); /* Standard syntax */
}

@media (max-width: 600px) {
  .ribbon {
    display: none !important;
  }
}
</style>
