import _ from 'lodash';

const state = {
  navInvisible: false,
};

const actions = {
  toggleNavbar({ commit }, { navbarInvisible }) {
    commit('toggleNavbar', { navbarInvisible });
  },
};

const mutations = {
  toggleNavbar(state, { navbarInvisible }) {
    state.navInvisible = navbarInvisible;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
