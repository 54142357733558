import axios from 'axios';
import configService from '@/services/configService';

export default {
  async getPosts(sistema, count) {
    axios.defaults.headers.common['Authorization'] = '';
    return axios
      .get(`https://cms.easytermos.com.br/posts?sistema.Nome=${sistema}`)
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        throw e;
      });
  },
  async getPost(sistema, id) {
    axios.defaults.headers.common['Authorization'] = '';
    return axios
      .get(
        `https://cms.easytermos.com.br/posts?sistema.Nome=${sistema}&seo_url=${id}`
      )
      .then((response) => {
        return response.data[0];
      })
      .catch((e) => {
        throw e;
      });
  },
};
