import axios from 'axios';
import * as vueConfig from '../../vue.config';
import data from '@/assets/config.json';

class ConfigService {
  constructor() {
    this.config = {
      format: 'DD/MM/YYYY',
    };
  }

  loadConfig() {
    this.config.apiUrl = window.location.hostname + '/api';
    if (
      !this.config.apiUrl.includes('https://') &&
      !this.config.apiUrl.includes('localhost')
    ) {
      this.config.apiUrl = 'https://' + this.config.apiUrl;
    } else if (this.config.apiUrl.includes('localhost')) {
      this.config.apiUrl = 'http://localhost:3000';
    }

    return this.config.apiUrl;
  }
  set(key, value) {
    this.config[key] = value;
  }

  get(key) {
    return this.config[key];
  }
}

export default new ConfigService();
