<template>
  <div id="features ">
    <section class="text-gray-700 body-font bg-gray-100">
      <div class="container px-5 py-20 mx-auto">
        <div
          class="flex flex-wrap w-full mb-24 flex-col items-center text-center"
        >
          <h2
            class="mt-4 max-w-3xl text-center text-xl font-extrabold text-gray-900 sm:mt-5 sm:leading-no leading-none ne sm:text-5xl lg:mt-6 lg:text-3xl xl:text-4xl"
          >
            Para qualquer tipo de negócio online
          </h2>

          <p class="text-xl max-w-3xl text-center text-gray-700 mt-6">
            Nossos documentos seguem todas as diretrizes pela LGPD, bem como as
            boas práticas de mercados nacionais e internacionais.
          </p>
        </div>

        <div
          class="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6"
        >
          <div class="p-4 md:w-1/4 flex flex-col text-center items-center">
            <div
              class="w-20 h-20 inline-flex items-center justify-center text-indigo-500 mb-5 flex-shrink-0"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                class="w-12 h-12 text-purple-500"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <div class="flex-grow">
              <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                SaaS / Aplicativos Web
              </h2>
              <p class="leading-relaxed text-base">
                Coletando informações pessoais? Gere uma política fácil que seus
                usuários possam entender e adeque sua empresa.
              </p>
              <btn
                page="/document/1/politicas-privacidade/MQ=="
                class="text-sm"
                type="outline"
                text="Proteger minha empresa sem custos"
              ></btn>
            </div>
          </div>
          <div class="p-4 md:w-1/4 flex flex-col text-center items-center">
            <div
              class="w-20 h-20 inline-flex items-center justify-center text-indigo-500 mb-5 flex-shrink-0"
            >
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="w-10 h-10"
                viewBox="0 0 24 24"
              >
                <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
              </svg>
            </div>
            <div class="flex-grow">
              <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                E-Commerces
              </h2>
              <p class="leading-relaxed text-base">
                Ajude seus clientes a comprarem com confiança, com uma Política
                de Privacidade, Política de Cookies e Termos de Serviço
                próprios.
              </p>
              <btn
                page="/document/1/politicas-privacidade/MQ=="
                class="text-sm"
                type="outline"
                text="Gerar política para o meu e-commerce"
              ></btn>
            </div>
          </div>

          <div class="p-4 md:w-1/4 flex flex-col text-center items-center">
            <div
              class="w-20 h-20 inline-flex items-center justify-center text-indigo-500 mb-5 flex-shrink-0"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                class="w-12 h-12 text-green-600"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z"
                />
              </svg>
            </div>
            <div class="flex-grow">
              <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                Blogs e Sites de Notícias
              </h2>
              <p class="leading-relaxed text-base">
                Forneça com custos baixos ao seu público uma Política de
                Privacidade, Política de Cookies e Termos de Uso claros e
                simples.
              </p>
              <btn
                page="/document/1/politicas-privacidade/MQ=="
                class="text-sm"
                type="outline"
                text="Adequar meu site de notícias a LGPD"
              ></btn>
            </div>
          </div>
          <div class="p-4 md:w-1/4 flex flex-col text-center items-center">
            <div
              class="w-20 h-20 inline-flex items-center justify-center text-indigo-500 mb-5 flex-shrink-0"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="w-12 h-12 text-blue-500"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
                />
              </svg>
            </div>
            <div class="flex-grow">
              <h2 class="text-gray-900 text-lg title-font font-medium mb-3">
                Aplicativos Móveis
              </h2>
              <p class="leading-relaxed text-base">
                Desenvolvendo um aplicativo para iOS ou Android? Gere uma
                Política de Privacidade personalizada para seu aplicativo em
                apenas 2 minutos.
              </p>
              <btn
                page="/document/1/politicas-privacidade/MQ=="
                class="text-sm"
                type="outline"
                text="Termos de uso para o meu aplicativo"
              ></btn>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Btn from '@/components/Home/HomeET/Btn';
export default {
  name: 'ForAllCompanies',
  components: { Btn },
};
</script>
