<template>
  <div
    class="z-50 fixed top-10 right-10 rounded-md p-4 border-l-4"
    :class="{
      'bg-green-100': alertType === 'success',
      'bg-red-100': alertType === 'error',
      'bg-blue-100': alertType === 'info',
      'border-green-400': alertType === 'success',
      'border-red-400': alertType === 'error',
      'border-blue-400': alertType === 'info',
    }"
    style="z-index: 1000000"
  >
    <div class="flex">
      <div class="flex-shrink-0">
        <svg
          class="h-5 w-5 text-green-400"
          fill="currentColor"
          viewBox="0 0 20 20"
          v-if="alertType === 'success'"
        >
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
            clip-rule="evenodd"
          />
        </svg>
        <svg
          class="h-5 w-5 text-blue-400"
          fill="currentColor"
          viewBox="0 0 20 20"
          v-if="alertType === 'info'"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clip-rule="evenodd"
          />
        </svg>
        <svg
          class="h-5 w-5 text-red-400"
          fill="currentColor"
          viewBox="0 0 20 20"
          v-if="alertType === 'error'"
        >
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <div class="ml-3">
        <p
          class="text-sm leading-5 font-medium"
          :class="{
            'text-green-700': alertType === 'success',
            'text-red-700': alertType === 'error',
            'text-blue-700': alertType === 'info',
          }"
        >
          <b>{{ alertTitle }}</b>
        </p>
        <p
          class="text-sm leading-5 font-medium"
          :class="{
            'text-green-700': alertType === 'success',
            'text-red-700': alertType === 'error',
            'text-blue-700': alertType === 'info',
          }"
        >
          {{ alertText }}
        </p>
      </div>
      <div class="ml-auto pl-3">
        <div class="-mx-1.5 -my-1.5">
          <button
            :class="{
              'text-green-700': alertType === 'success',
              'text-red-700': alertType === 'error',
              'text-blue-700': alertType === 'info',
              'hover:bg-green-300': alertType === 'success',
              'hover:bg-red-300': alertType === 'error',
              'hover:bg-blue-300': alertType === 'info',
              'focus:text-green-200': alertType === 'success',
              'focus:text-red-200': alertType === 'error',
              'focus:text-blue-200': alertType === 'info',
            }"
            @click="clear"
            class="inline-flex rounded-md p-1.5 focus:outline-none transition ease-in-out duration-150"
          >
            <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex';

export default {
  name: 'Alert',
  created() {
    setTimeout(() => this.clear(), 3500);
  },
  computed: {},
  props: {
    alertType: String,
    alertPosition: String,
    alertText: String,
    alertTitle: String,
  },
  methods: {
    ...mapActions('alert', ['clear']),
  },
};
</script>

<style scoped></style>
