import { render, staticRenderFns } from "./BlogCO.vue?vue&type=template&id=1481124a&scoped=true&"
import script from "./BlogCO.vue?vue&type=script&lang=js&"
export * from "./BlogCO.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1481124a",
  null
  
)

export default component.exports