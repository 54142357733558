import store from '@/store';

import Vue from 'vue';
import Router from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(Router);

const router = new Router({
  base: process.env.BASE_URL || '/',
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/login',
      name: 'login',
      component: () =>
        import(/* webpackChunkName: "login" */ '../views/Account/Login.vue'),
    },
    {
      path: '/register',
      name: 'register',
      component: () =>
        import(/* webpackChunkName: "login" */ '../views/Account/Register.vue'),
    },
    {
      path: '/find-password',
      name: 'find-password',
      component: () =>
        import(
          /* webpackChunkName: "login" */ '../views/Account/FindPassword.vue'
        ),
    },
    {
      path: '/password-reset',
      name: 'password-reset',
      component: () =>
        import(
          /* webpackChunkName: "login" */ '../views/Account/PasswordReset.vue'
        ),
    },
    {
      path: '/frontend-vue',
      name: 'frontend',
      component: () => Home,
      meta: {},
    },
    {
      path: '/app',
      name: 'app',
      component: () =>
        import(
          /* webpackChunkName: "dashboard" */ '../views/App/Dashboard.vue'
        ),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/minha-conta',
      name: 'account',
      component: () =>
        import(
          /* webpackChunkName: "dashboard" */ '../views/Account/Account.vue'
        ),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/account/update',
      name: 'account-update',
      component: () =>
        import(
          /* webpackChunkName: "dashboard" */ '../views/Account/AccountUpdate.vue'
        ),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/logout',
      name: 'logout',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "login" */ '../views/Account/Logout.vue'),
    },
    {
      path: '/doc/:nm_document/:id_document/:id_user',
      name: 'document',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(
          /* webpackChunkName: "footer" */ '../views/Doc/DocumentOverview.vue'
        ),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/doc/create/:nm_document/:id_document/:id_user',
      name: 'documentCreate',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(
          /* webpackChunkName: "footer" */ '../views/Doc/DocumentCreate.vue'
        ),
      meta: {},
    },
    {
      path: '/document/:id_plan/:nm_document/:id_document',
      name: 'documentCreateAndRegister',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(
          /* webpackChunkName: "footer" */ '../views/Doc/DocumentCreateAndRegister.vue'
        ),
      meta: {},
    },
    {
      path: '/doc/update/:nm_document/:id_document/:id_user',
      name: 'documentUpdate',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(
          /* webpackChunkName: "footer" */ '../views/Doc/DocumentUpdate.vue'
        ),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/doc/embed/:nm_document/:id_document/:id_user',
      name: 'documentEmbed',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(
          /* webpackChunkName: "footer" */ '../views/Doc/DocumentEmbed.vue'
        ),
    },
    {
      path: '/doc/preview/:nm_document/:id_document/:id_user',
      name: 'documentPreview',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(
          /* webpackChunkName: "footer" */ '../views/Doc/DocumentPreview.vue'
        ),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/checkout',
      name: 'Checkout',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(
          /* webpackChunkName: "footer" */ '../views/Checkout/PreCheckout.vue'
        ),
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: '/artigos',
      name: 'artigos',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "blog" */ '../views/Blog/Blog.vue'),
    },
    {
      path: '/blog',
      name: 'blog',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "blog" */ '../views/Blog/Blog.vue'),
    },
    {
      path: '/artigos/:id',
      name: 'blogPost',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "blog" */ '../views/Blog/BlogPage.vue'),
    },
    {
      path: '/pagina/*/exemplo',
      name: 'DemoPage',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "demo" */ '../views/Politicas/Demo.vue'),
    },
    {
      path: '/pagina/:id',
      name: 'pagina',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "page" */ '../views/Page/Page.vue'),
    },
    {
      path: '/contato',
      name: 'contato',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(
          /* webpackChunkName: "contact" */ '../views/Contact/Contact.vue'
        ),
    },
    {
      path: '/politicas/politica-para-sites',
      name: 'PolicySites',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "contact" */ '../views/Politicas/Site.vue'),
    },
    {
      path: '/politicas/politica-para-aplicativos-mobile',
      name: 'PolicySites',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(
          /* webpackChunkName: "contact" */ '../views/Politicas/Mobile.vue'
        ),
    },
    {
      path: '/politicas/politica-de-uso-para-saas',
      name: 'PolicySites',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "contact" */ '../views/Politicas/SaaS.vue'),
    },
    {
      path: '/politicas/politica-de-uso-para-ecommerces',
      name: 'PolicySites',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(
          /* webpackChunkName: "contact" */ '../views/Politicas/Ecommerce.vue'
        ),
    },
    {
      path: '/politicas/politica-de-uso-para-blogs',
      name: 'PolicySites',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "contact" */ '../views/Politicas/Blog.vue'),
    },
    {
      path: '/politicas/politica-de-uso-para-wordpress',
      name: 'PolicySites',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(
          /* webpackChunkName: "contact" */ '../views/Politicas/Wordpress.vue'
        ),
    },
    {
      path: '/ser-um-parceiro-easy-termos',
      name: 'Partner',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "contact" */ '../views/Partner.vue'),
    },
    {
      path: '/politicas/*/exemplo',
      name: 'DemoPolicies',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "demo" */ '../views/Politicas/Demo.vue'),
    },
    {
      path: '/vagas',
      name: 'vagas',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "contact" */ '../views/Contact/Jobs.vue'),
    },
    {
      path: '/perguntas-frequentes',
      name: 'faq',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "contact" */ '../views/FAQ/FAQ.vue'),
    },
    {
      path: '/404',
      name: '404',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(
          /* webpackChunkName: "notFound" */ '../views/NotFound/NotFound.vue'
        ),
    },
    {
      path: '/portal-usuario',
      name: 'Portal',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "login" */ '../views/Account/Payment.vue'),
    },
    {
      path: '/termos',
      name: 'Portal',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "login" */ '../views/Termos.vue'),
    },
    {
      path: '/privacidade',
      name: 'Portal',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "login" */ '../views/Privacidade.vue'),
    },
    {
      path: '*',
      name: 'Error',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      redirect: '/404',
    },
  ],
  scrollBehavior() {
    return { x: 0, y: -200 };
  },
});

router.beforeEach((to, _from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters['auth/isLoggedIn']()) {
      next();
    } else {
      next('/login');
    }
  } else {
    next();
  }
});

export default router;
