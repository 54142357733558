<template>
  <div class="relative bg-gray-50 pt-12 pb-20 px-4 sm:px-6">
    <div class="absolute inset-0">
      <div class="bg-white h-1/3 sm:h-2/3"></div>
    </div>
    <div class="relative max-w-7xl mx-auto">
      <div class="text-center">
        <h2
          class="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10"
        >
          Artigos sobre proteção de dados.
        </h2>
        <p
          class="mt-3 max-w-2xl mx-auto text-xl leading-7 text-gray-500 sm:mt-4"
        >
          Confira nossos artigos, guias e anúncios para manter-se atualizado
          sobre todas as regras e mudanças relacionadas a privacidade de dados
          que podem afetar seus negócios.
        </p>
      </div>
      <div
        class="mt-12 grid gap-5 max-w-lg mx-auto lg:grid-cols-3 lg:max-w-none"
      >
        <div
          v-for="(item, index) in posts.slice(0, 3)"
          v-bind:key="index"
          class="flex flex-col rounded-sm shadow-md overflow-hidden"
        >
          <div class="flex-shrink-0">
            <img
              class="h-48 w-full object-cover"
              :src="
                'https://cms.easytermos.com.br' +
                item.foto_destaque.formats.large.url
              "
              :alt="item.seo_url"
              loading="lazy"
            />
          </div>
          <div class="flex-1 bg-white p-6 flex flex-col justify-between">
            <div class="flex-1">
              <p class="text-sm leading-5 font-medium text-indigo-600">
                <a :href="'/artigos/' + item.seo_url" class="hover:underline">
                  {{ item.categoria.Nome }}
                </a>
              </p>
              <a :href="'/artigos/' + item.seo_url" class="block">
                <h3 class="mt-2 text-xl leading-7 font-semibold text-gray-900">
                  {{ item.title }}
                </h3>
                <p class="mt-3 text-base leading-6 text-gray-500">
                  <vue-showdown
                    class="blog-post"
                    :markdown="item.post_text.slice(0, 200) + '…'"
                    flavor="github"
                    :options="{ emoji: true }"
                  />
                </p>
              </a>
            </div>
            <div class="mt-6 flex items-center">
              <div class="">
                <p class="text-sm leading-5 font-medium text-gray-900">
                  <a href="/artigos" class="hover:underline">
                    {{ item.autor.Nome }}
                  </a>
                </p>
                <div class="flex text-sm leading-5 text-gray-500">
                  <time datetime="2020-03-16"> {{ item.data }} </time>
                  <span class="mx-1"> &middot; </span>
                  <span>
                    {{ Math.floor(Math.random() * 10 + 5) }} minutos de leitura
                    .
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex items-center justify-center space-x-0 md:space-x-2 space-y-2 md:space-y-0 mt-20 flex-col md:flex-row"
      >
        <a
          href="/artigos"
          class="inline-flex center items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-et-500 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
        >
          Ver todos os artigos
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import router from '@/router';

export default {
  name: 'BlogCO',
  data() {
    return {
      home: false,
      blogPosts: [],
    };
  },
  created() {
    this.showPosts();
  },
  computed: {
    ...mapState('blog', ['posts']),
  },
  methods: {
    ...mapActions('blog', ['getPosts']),
    async showPosts() {
      await this.getPosts({ sistema: 'EasyTermos', count: 0 });
    },
  },
};
</script>

<style scoped></style>
