<template>
  <section
    class="min-h-screen fixed z-50 w-full bg-white mt-0 grid grid-cols-1 gap-0 lg:grid-cols-12"
  >
    <div class="col-span-1 lg:col-span-6">
      <img
        src="https://images.unsplash.com/photo-1531548731165-c6ae86ff6491?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=967&q=80"
        alt="3 women looking at a laptop"
        class="object-cover w-full h-64 min-h-full bg-gray-100"
        loading="lazy"
      />
    </div>
    <div
      class="w-full col-span-1 p-4 mx-auto mt-6 lg:col-span-6 xl:p-12 sm:w-2/4 lg:w-full"
    >
      <a
        href="/"
        title="Compliance Home Page"
        class="flex items-center justify-start mt-8"
      >
        <img
          src="@/assets/img/logo-compliance-escuro.svg"
          alt=""
          class="h-6 rounded-sm"
        />
        <span class="sr-only">Compliance Online</span>
      </a>
      <section
        v-if="showLoginModal"
        class="sm:w-full lg:w-2/3"
        :class="{
          'bg-white': home === 'complianceOnline',
        }"
      >
        <h1 class="mt-8 mb-4 text-xl font-light text-left text-gray-800">
          Entre na sua conta
        </h1>

        <div>
          <form
            @submit.stop.prevent="onSubmit"
            class="space-y-4 mb-8"
            data-cy="login-form"
          >
            <label class="block">
              <span class="block mb-1 text-xs font-medium text-gray-700"
                >Seu email</span
              >
              <input
                v-model="form.username"
                id="input-username"
                type="text"
                required
                placeholder="Ex: pedro@email.com"
                :state="
                  $v.form.username.$dirty ? !$v.form.username.$error : null
                "
                data-cy="login-username"
                class="form-input text-sm"
                :class="{ 'border-10 border-red-500': errorMessages }"
              />
            </label>
            <label class="block">
              <span class="block mb-1 text-xs font-medium text-gray-700"
                >Sua Senha</span
              >
              <input
                id="input-password"
                type="password"
                required
                v-model="form.password"
                placeholder="Sua senha"
                :state="
                  $v.form.password.$dirty ? !$v.form.password.$error : null
                "
                data-cy="login-password"
                class="form-input text-sm"
                :class="{ 'border-10 border-red-500': errorMessages }"
              />
            </label>
            <span
              v-if="errorMessages"
              class="text-red-600 font-light text-xs"
              >{{ errorMessages }}</span
            >
            <div class="flex items-center justify-between">
              <label class="flex items-center">
                <input type="checkbox" class="form-checkbox" />
                <span
                  class="block ml-2 text-xs font-medium text-gray-700 cursor-pointer"
                  >Lembrar minha conta</span
                >
              </label>
              <input
                type="submit"
                class="rounded hover:bg-gray-800 transition duration-100 shadow-lg cursor-pointer text-white px-2 text-sm py-2 mt-1 self-end float-right"
                value="Entrar em minha conta"
                :class="{
                  'bg-co-500': home === 'complianceOnline',
                  'bg-indigo-700': home === 'easyTermos',
                }"
              />
            </div>
          </form>
        </div>
      </section>
      <section
        v-if="showRegisterModal"
        class="sm:w-full lg:w-2/3"
        :class="{
          'bg-white': home === 'complianceOnline',
        }"
      >
        <div v-if="!isRegistered">
          <h1 class="mt-8 mb-4 text-xl font-light text-left text-gray-800">
            Faça seu cadastro
          </h1>
          <h1 class="text-lg text-center font-semibold mb-4 text-gray-900"></h1>
          <form
            @submit.stop.prevent="onSubmitRegister"
            class="space-y-4 mb-8"
            data-cy="login-form"
          >
            <label class="block">
              <span class="text-xs text-gray-700 font-medium block mb-1"
                >Seu email</span
              >
              <input
                v-model="formRegister.email"
                type="text"
                required
                placeholder="Seu email"
                :state="
                  $v.formRegister.username.$dirty
                    ? !$v.formRegister.username.$error
                    : null
                "
                data-cy="login-username"
                class="form-input text-sm"
                :class="{ ' border-10 border-red-500 ': errorMessages }"
              />
            </label>
            <label class="block">
              <span class="text-xs text-gray-700 font-medium block mb-1"
                >Primeiro Nome</span
              >
              <input
                type="text"
                required
                v-model="formRegister.firstName"
                placeholder="Nome"
                :state="
                  $v.formRegister.firstName.$dirty
                    ? !$v.formRegister.firstName.$error
                    : null
                "
                data-cy="login-first-name"
                class="form-input text-sm"
                :class="{ 'border-10 border-red-500': errorMessages }"
              />
            </label>
            <label class="block">
              <span class="text-xs text-gray-700 font-medium block mb-1"
                >Sobrenome</span
              >
              <input
                type="text"
                required
                v-model="formRegister.lastName"
                placeholder="Nome"
                :state="
                  $v.formRegister.lastName.$dirty
                    ? !$v.formRegister.lastName.$error
                    : null
                "
                data-cy="login-last-name"
                class="form-input text-sm"
                :class="{ 'border-10 border-red-500': errorMessages }"
              />
            </label>
            <label class="block">
              <span class="text-xs text-gray-700 font-medium block mb-1"
                >Sua Senha</span
              >
              <input
                type="password"
                required
                v-model="formRegister.password"
                placeholder="Sua senha"
                :state="
                  $v.formRegister.password.$dirty
                    ? !$v.formRegister.password.$error
                    : null
                "
                data-cy="login-password"
                class="form-input text-sm"
                :class="{ 'border-10 border-red-500': errorMessages }"
              />
            </label>
            <label class="flex items-center">
              <input type="checkbox" class="form-checkbox" required />
              <span
                class="ml-2 cursor-pointer text-xs text-gray-600 font-medium block mb-1"
                >Aceito os
                <router-link target="_blank" to="/termos"
                  >Termos de Uso</router-link
                >
                e
                <router-link target="_blank" to="/privacidade"
                  >a Política de Privacidade</router-link
                ></span
              >
            </label>
            <span
              v-if="errorMessages"
              class="text-red-600 font-light text-xs"
              >{{ errorMessages }}</span
            >
            <span v-else class="text-white">#</span>

            <input
              type="submit"
              :class="{
                disabled: loading,
                'bg-co-500': home === 'complianceOnline',
              }"
              class="rounded disabled shadow-lg cursor-pointer text-white text-sm hover:bg-gray-800 transition duration-100 m-0 m-auto ml-0 py-2 w-full"
              value="Cadastrar conta"
            />
          </form>
        </div>
        <div v-else>
          <h1 class="mt-8 mb-4 text-xl font-light text-left text-gray-800">
            Obrigado por se cadadastrar
          </h1>
          <div>
            <h2 class="text-sm text-gray-800">
              Você irá receber um email de confirmação para a sua conta
            </h2>
            <p class="text-sm text-gray-800 mt-2">
              Caso não receba, confirme o email digitado e tente novamente
            </p>
          </div>
        </div>
      </section>
      <section
        v-if="showRecoverModal"
        class="sm:w-full lg:w-2/3"
        :class="{
          'bg-white': home === 'complianceOnline',
        }"
      >
        <div v-if="!isRegistered">
          <h1 class="mt-8 mb-4 text-xl font-light text-left text-gray-800">
            Recuperar sua senha
          </h1>
          <form
            v-if="!isPasswordResetRequested"
            @submit.stop.prevent="onSubmitRecover"
            class="space-y-4 mb-8"
            data-cy="login-form"
          >
            <label class="block">
              <span class="text-xs text-gray-700 font-medium block mb-1"
                >Seu email</span
              >
              <input
                v-model="formRecover.email"
                id="input-recover-email"
                type="text"
                required
                placeholder="Seu email"
                :state="
                  $v.formRecover.email.$dirty
                    ? !$v.formRecover.email.$error
                    : null
                "
                data-cy="login-username"
                class="form-input text-sm"
                :class="{ 'border-10 border-red-500': errorMessages }"
              />
            </label>

            <template v-if="successMessages || errorMessages">
              <p v-if="successMessages" class="text-xs text-indigo-500">
                {{ successMessages }}
              </p>
              <p v-if="errorMessages" class="text-xs text-red-500">
                {{ errorMessages }}
              </p>
            </template>
            <input
              type="submit"
              class="rounded shadow-lg cursor-pointer text-white text-sm hover:bg-gray-800 transition duration-100 m-0 m-auto ml-0 py-2 w-full"
              value="Recuperar senha"
              :class="{
                'bg-co-500': home === 'complianceOnline',
              }"
            />
          </form>
          <div v-else>
            <h2 class="text-sm text-gray-800">
              Você irá receber um email de confirmação para a troca de senha
            </h2>
            <p class="text-sm text-gray-800 mt-2">
              Caso não receba, confirme o email digitado e tente novamente
            </p>
            <p
              @click="clearStates"
              class="btn btn-primary mt-4 w-1/3 bg-co-500 rounded shadow-lg cursor-pointer text-white text-sm hover:bg-gray-800 transition duration-100 m-0 m-auto ml-0 py-2 w-1/2"
            >
              Tentar novamente
            </p>
          </div>
        </div>
      </section>
      <div class="my-6 space-y-2">
        <p class="text-xs text-gray-600" v-if="!showLoginModal">
          Já tem um usuário?
          <a
            href="#"
            class="text-yellow-700 hover:text-black"
            @click="toggleModal('login')"
            >Fazer Login</a
          >
        </p>
        <a
          href="#"
          class="block text-xs text-yellow-700 hover:text-black"
          @click="toggleModal('recover')"
          >Esqueci minha senha</a
        >
        <router-link to="/termos" tag="a" class="block text-xs text-yellow-700 hover:text-black"
          >Privacidade & Termos</router-link
        >
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { email, required, minLength } from 'vuelidate/lib/validators';
import router from '@/router';

export default {
  name: 'LoginBox',
  data() {
    return {
      home: false,
      formRecover: {
        email: '',
      },
      formRegister: {
        username: '',
        password: '',
        email: '',
        firstName: '',
        lastName: '',
      },
      form: {
        username: '',
        password: '',
        email: '',
      },
      modalLogin: true,
      showLoginModal: true,
      showRegisterModal: false,
      showRecoverModal: false,
    };
  },
  validations: {
    formRecover: {
      email: {
        required,
        email,
      },
    },
    form: {
      email: {
        required,
        email,
      },
      username: {
        required,
      },
      password: {
        required,
        minLength: minLength(6),
      },
    },
    formRegister: {
      email: {
        required,
        email,
      },
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      username: {
        required,
      },
      password: {
        required,
        minLength: minLength(6),
      },
    },
  },
  mounted() {
    const url = document.URL;
    if (url.includes('compliance')) {
      this.home = 'complianceOnline';
    } else if (url.includes('easy')) {
      this.home = 'easyTermos';
    }
    process.env.VUE_APP_SYSTEM !== null && url.includes('local')
      ? (this.home = process.env.VUE_APP_SYSTEM)
      : '';

    this.$store.commit('auth/clear');

    if (this.isLoggedIn()) {
      // Already logged in
      return;
    }
  },
  computed: {
    ...mapGetters('alert', ['errorMessages', 'successMessages']),
    ...mapState('auth', [
      'loading',
      'isPasswordResetRequested',
      'isRegistered',
    ]),
    ...mapGetters('auth', ['isLoggedIn']),
    ...mapState('modal', ['isCreatingDocument']),
  },
  methods: {
    ...mapActions('auth', [
      'login',
      'logout',
      'passwordResetRequest',
      'register',
    ]),
    toggleModal(param) {
      if (param === 'login') {
        this.showLoginModal = true;
        this.showRegisterModal = false;
        this.showRecoverModal = false;
      } else if (param === 'register') {
        this.showLoginModal = false;
        this.showRegisterModal = true;
        this.showRecoverModal = false;
      } else {
        this.showLoginModal = false;
        this.showRegisterModal = false;
        this.showRecoverModal = true;
      }
    },
    onSubmitRegister() {
      if (this.$v.form.$anyError) {
        return;
      }

      const url = document.URL;
      let referer;
      if (url.includes('compliance')) {
        referer = 'complianceOnline';
      } else if (url.includes('easy')) {
        referer = 'easyTermos';
      } else {
        referer = 'localhost';
      }

      this.register({
        email: this.formRegister.email,
        firstName: this.formRegister.firstName,
        lastName: this.formRegister.lastName,
        username: this.formRegister.email,
        password: this.formRegister.password,
        referer: referer,
        router,
      });
    },
    onSubmit() {
      if (this.$v.form.$anyError) {
        return;
      }

      // Form submit logic
      this.login({
        username: this.form.username,
        password: this.form.password,
        router,
        isCreatingDocument: this.isCreatingDocument,
      });
    },
    clearStates() {
      this.$store.commit('auth/clear');
    },
    onSubmitRecover() {
      if (this.$v.form.$anyError) {
        return;
      }

      const url = document.URL;
      let referer;
      if (url.includes('compliance')) {
        referer = 'complianceOnline';
      } else if (url.includes('easy')) {
        referer = 'easyTermos';
      } else {
        referer = 'localhost';
      }

      // Form submit logic
      this.passwordResetRequest({
        email: this.formRecover.email,
        referer: referer,
        router,
      }).then((res) => {});
    },
  },
};
</script>
