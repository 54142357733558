<template>
  <section class="container mx-auto py-16 xs:py-20 sm:py-20 md:py-48 px-4">
    <main class="mt-8 sm:mt-0 md:mt-10 lg:mt-0">
      <div class="mx-auto max-w-screen-xl">
        <div class="lg:grid lg:grid-cols-12 lg:gap-8">
          <div
            class="px-4 sm:px-6 sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left lg:flex lg:items-center"
          >
            <div>
              <a
                href="/vagas"
                class="hidden md:inline-flex inline-flex items-center text-white bg-gray-800 rounded-full p-1 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-gray-200"
              >
                <span
                  class="px-3 py-0.5 text-black text-xs font-semibold leading-5 uppercase tracking-wide bg-coYellow-500 rounded-full"
                  >Estamos contratando</span
                >
                <span class="ml-4 text-sm leading-5">Envie um email</span>
                <svg
                  class="hidden md:flex ml-2 w-5 h-5 text-gray-500"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </a>
              <h2
                class="mt-4 text-3xl tracking-tight leading-10 font-extrabold text-white sm:mt-5 sm:leading-none sm:text-5xl lg:mt-6 lg:text-4xl xl:text-5xl"
              >
                Adeque sua empresa às normas da
                <span class="text-coYellow-500">LGPD</span>
              </h2>
              <div
                class="my-4 sm:text-xs text-sm inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-coYellow-500 text-black rounded-full"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-archive mr-2"
                >
                  <polyline points="21 8 21 21 3 21 3 8"></polyline>
                  <rect x="1" y="3" width="22" height="5"></rect>
                  <line x1="10" y1="12" x2="14" y2="12"></line>
                </svg>
                <p class="text-xs">
                  OTIMIZADO PARA E-COMMERCES, MARKETPLACES, SAAS, APPS E MAIS
                </p>
              </div>
              <p
                class="text-base text-gray-300 sm:text-md lg:text-md xl:text-md"
              >
                A <span class="text-extra-bold">compliance online</span> te
                ajuda a deixar sua empresa regulada junto as normas da nova
                LGPD, além de prover serviços de termos de uso, política de
                privacidade e mais para ajudar seu usuário a se sentir seguro no
                seu sistema.
              </p>
            </div>
          </div>
          <div class="mt-12 sm:mt-16 lg:mt-0 lg:col-span-6">
            <div
              class="bg-white sm:max-w-md sm:w-full sm:mx-auto sm:rounded-lg sm:overflow-hidden"
              v-if="!this.isLoggedIn()"
            >
              <div class="px-4 py-8 sm:px-10 relative">
                <router-link
                  to="/#pricing"
                  class="ribbon pl-16 bg-red-500 text-sm whitespace-no-wrap px-4"
                >
                  Black Friday
                </router-link>

                <div>
                  <p class="text-md pb-5 leading-5 font-medium text-gray-700">
                    Começar agora
                  </p>
                  <span class="pt-5 text-sm leading-1 text-gray-500">
                    Todos os documentos são entregues e revisados no momento da
                    compra. Para conferir todos os documentos
                    <a href="/#features" class="text-black">clique aqui</a>.
                  </span>
                </div>

                <div class="mt-6">
                  <div class="space-y-6">
                    <div>
                      <label for="name" class="sr-only">
                        Nome da sua empresa
                      </label>
                      <div class="rounded-md shadow-sm">
                        <input
                          id="name"
                          placeholder="Nome da sua empresa"
                          class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        />
                      </div>
                    </div>

                    <div>
                      <label for="mobile-or-email" class="sr-only">
                        E-mail
                      </label>
                      <div class="rounded-md shadow-sm">
                        <input
                          id="mobile-or-email"
                          placeholder="E-mail"
                          class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        />
                      </div>
                    </div>

                    <div>
                      <span class="block w-full rounded-md shadow-sm">
                        <router-link
                          to="/login"
                          class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-co-500 hover:bg-co-500 hover:opacity-75 focus:outline-none transition duration-150 ease-in-out"
                        >
                          Gerar documentos
                        </router-link>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="px-4 py-6 bg-gray-50 border-t-2 border-gray-200 sm:px-10"
              >
                <p class="text-xs leading-5 text-gray-500">
                  Ao se inscrever você concorda com nossos
                  <a
                    href="/termos"
                    class="font-medium text-gray-900 hover:underline"
                    >Termos</a
                  >,
                  <a
                    href="/privacidade"
                    class="font-medium text-gray-900 hover:underline"
                    >Políticas de Dados</a
                  >
                  e
                  <a
                    href="/privacidade"
                    class="font-medium text-gray-900 hover:underline"
                    >Políticas de Cookies</a
                  >.
                </p>
              </div>
            </div>
            <div
              class="bg-white sm:max-w-md sm:w-full sm:mx-auto sm:rounded-lg sm:overflow-hidden"
              v-else
            >
              <div class="px-4 py-8 sm:px-10 relative">
                <router-link
                  to="/#pricing"
                  class="ribbon pl-16 bg-red-500 text-sm whitespace-no-wrap px-4"
                >
                  Black Friday
                </router-link>

                <div>
                  <p class="text-md pb-5 leading-5 font-medium text-gray-700">
                    Começar agora
                  </p>
                  <span class="pt-5 text-sm leading-1 text-gray-500">
                    Todos os documentos são entregues e revisados no momento da
                    compra. Para conferir todos os documentos
                    <a href="/#features" class="text-black">clique aqui</a>.
                  </span>
                </div>

                <div class="mt-6">
                  <div class="space-y-6">
                    <div>
                      <span class="mt-16 block w-full rounded-md shadow-sm">
                        <router-link
                          to="/login"
                          class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-co-500 hover:bg-co-500 hover:opacity-75 focus:outline-none transition duration-150 ease-in-out"
                        >
                          Acessar meus documentos
                        </router-link>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </section>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'Hero.vue',
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
  },
};
</script>

<style scoped>
.ribbon {
  position: absolute;
  top: 0;
  right: 0;
  width: 200px;
  height: 22px;
  margin-right: -50px;
  margin-top: 30px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Safari prior 9.0 */
  transform: rotate(45deg); /* Standard syntax */
}

@media (max-width: 600px) {
  .ribbon {
    display: none !important;
  }
}
</style>
