import _ from 'lodash';
import documentService from '@/services/documentService';
import stripeService from '@/services/stripeService';

const documentsOnlyDownload = [
  'relatorio-impacto',
  'registro-operacoes',
  'guia-utilizacao',
];

const documentsXLSM = ['relatorio-impacto'];

const documentsXLSX = ['registro-operacoes'];

const documentsPDF = ['guia-utilizacao'];

const state = {
  loading: false,
  error: null,
  selectedDocument: null,
  document: {},
  documents: [],
  styles: {},
};

const actions = {
  getOneDocument({ commit }, { id_user, id_document }) {
    if (!id_user || !id_document) return;

    documentService
      .getOneDocument({ id_user, id_document })
      .then((response) => {
        commit('setDocument', response.message);
      })
      .catch((e) => {
        commit('getDocumentFailure', e);
      });
  },

  getAllDocuments({ commit }, { id_user }) {
    if (!id_user) return;

    documentService
      .getAllDocuments({ id_user })
      .then((response) => {
        response.message.map((document) => {
          if (documentsOnlyDownload.includes(document.document)) {
            document.only_download = true;
          }

          if (documentsXLSM.includes(document.document)) {
            document.document = `${document.document}.xlsm`;
            document.download_name = `${document.nm_document}.xlsm`;
          }

          if (documentsXLSX.includes(document.document)) {
            document.document = `${document.document}.xlsx`;
            document.download_name = `${document.nm_document}.xlsx`;
          }

          if (documentsPDF.includes(document.document)) {
            document.document = `${document.document}.pdf`;
            document.download_name = `${document.nm_document}.pdf`;
          }

          return document;
        });

        commit('getAllDocumentsSuccess', response.message);
      })
      .catch((e) => {
        commit('getDocumentsFailure', e);
      });
  },
  registerDocument(
    { dispatch, commit },
    { document, user, id_document, router, redirect = true, showAlert = true }
  ) {
    documentService
      .createDocument({ id_user: user.id, document, id_document })
      .then(() => {
        if(showAlert){
          dispatch(
            'alert/success',
            {
              showType: 'toast',
              title: 'Successo',
              text: 'Documento criado com sucesso',
            },
            { root: true }
          );
        }

        if(redirect){
          router.push('/app');
        }
      })
      .catch((e) => {
        dispatch(
          'alert/error',
          {
            showType: 'toast',
            title: 'Error',
            text:
              'Ops, ocorreu um erro ao criar seu documento, verifique se você já não possui esse documento.',
          },
          { root: true }
        );

        console.log(e);
      });
  },
  registerDocumentEasy({ dispatch, commit }, { document, user, id_document }) {
    documentService
      .createDocument({ id_user: user.id, document, id_document })
      .then(() => {
        console.log('Documento criado com sucesso');
      })
      .catch((e) => {
        dispatch(
          'alert/error',
          {
            showType: 'toast',
            title: 'Error',
            text:
              'Ops, ocorreu um erro ao criar seu documento, verifique se você já não possui esse documento.',
          },
          { root: true }
        );

        console.log(e);
      });
  },
  selectDocument({ commit }, { document }) {
    commit('selectDocument', { document });

    localStorage.setItem('selected-document', document.id_document);
  },
  clearSelectedDocument({ commit }) {
    commit('clearSelectedDocument');

    localStorage.removeItem('selected-document');
  },
  getStylesFromDocument({ commit }, { id_user, id_document }) {
    if (!id_user || !id_document) return;

    documentService
      .getStylesFromDocument({ id_user, id_document })
      .then((response) => {
        commit('getStylesSuccess', response);
      })
      .catch((e) => {
        commit('getStylesFailure', e);
      });
  },

  updateStylesDocument({ dispatch }, { id_user, id_document, styles, router }) {
    documentService
      .updateStylesDocument({ id_user, id_document, styles })
      .then(() => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            title: 'Successo',
            text: 'Tema atualizado com sucesso',
          },
          { root: true }
        );
      })
      .catch(() => {
        dispatch(
          'alert/error',
          {
            showType: 'toast',
            title: 'Error',
            text: 'Ops, ocorreu um erro atualizar seu tema',
          },
          { root: true }
        );
      });
  },
  updateDocument({ dispatch }, { id_user, id_document, document, router }) {
    documentService
      .updateDocument({ id_user, id_document, document })
      .then(() => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            title: 'Successo',
            text: 'Documento atualizado com sucesso',
          },
          { root: true }
        );

        router.push('/app');
      })
      .catch(() => {
        dispatch(
          'alert/error',
          {
            showType: 'toast',
            title: 'Error',
            text: 'Ops, ocorreu um erro atualizar seu documento',
          },
          { root: true }
        );
      });
  },
  requestDownloadDocument(
    { dispatch },
    { document: Document, id_user, router }
  ) {
    documentService
      .requestDownloadFile({
        id_user,
        id_document: Document.id_document,
        document_name: Document.document,
      })
      .then((res) => {
        const linkDownload = document.createElement('a');
        linkDownload.href = URL.createObjectURL(res);
        linkDownload.download = Document.download_name;
        linkDownload.click();
      })
      .catch((err) => {
        dispatch(
          'alert/error',
          {
            showType: 'toast',
            title: 'Pagamento Necessário',
            text:
              'Você precisa ter acesso para realizar o download do arquivo.',
          },
          { root: true }
        );

        const url = document.URL;
        const statusError = err.response.status;

        // TODO CASO FOR TESTAR EM LOCAL E QUISER USAR O COMPLIACE MUDAR AQUI
        if (url.includes('compliance')) {
          switch (statusError) {
            case 401:
              stripeService.redirectToCheckoutCompliance();

              break;
            default:
              router.push('/app');
              break;
          }
        } else {
          switch (statusError) {
            case 401:
              router.push('/checkout');
              break;

            default:
              router.push('/app');
              break;
          }
        }
      });
  },
};

const mutations = {
  setDocument(state, response) {
    try {
      response.ds_json_props = JSON.parse(response.ds_json_props);
    } catch (e) {}

    // Verifico se estou utilizando o documento em modo de edição
    if (response.is_updating) {
      response.document = `${response.document}-form`;

      delete response.is_updating;
    }

    state.loading = false;
    state.document = response;
    state.error = false;
  },
  clearDocument(state) {
    state.document = {};
  },
  getDocumentFailure(state) {
    state.loading = false;
    state.error = state;
    state.document = {};
  },
  getStylesFailure(state) {
    state.loading = false;
    state.error = state;
    state.document = {};
  },
  getStylesSuccess(state, response) {
    state.loading = false;
    state.styles = response.message.ds_json_styles;
    state.error = false;
  },
  getAllDocumentsSuccess(state, response) {
    state.loading = false;
    state.documents = response;
    state.error = false;
  },
  selectDocument(state, { document }) {
    state.selectedDocument = document;
  },
  clearSelectedDocument(state) {
    state.selectedDocument = null;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
