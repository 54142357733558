<template>
  <router-link
    v-if="type === 'outline'"
    :to="page"
    class="btn border-2 border-et-500 hover:text-white duration-150 transition hover:bg-et-600 mt-6 text-et-500 font-regular rounded-full btn-md px-4 py-2 mx-auto leading-2"
  >
    {{ text }}
  </router-link>
  <router-link
    v-else
    :to="page"
    class="btn bg-et-500 duration-150 transition hover:bg-et-600 mt-6 text-white rounded-md btn-md tracking-tighter px-6 py-4 mx-auto leading-2"
  >
    {{ text }}
  </router-link>
</template>
<script>
export default {
  name: 'Btn',
  props: {
    page: {},
    text: {},
    type: {},
  },
};
</script>
