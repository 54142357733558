import _ from 'lodash';

import contactService from '@/services/contactService';

const state = {
  loading: false,
  error: null,
  alreadySent: false,
};

const actions = {
  sendEmail(
    { dispatch, commit },
    { name, message, email, tel, company, referer }
  ) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    contactService
      .sendEmail(name, message, email, tel, company, referer)
      .then((response) => {
        commit('emailSuccess', { response: 'Email enviado com sucesso' });
        dispatch(
          'alert/success',
          { showType: 'toast', title: 'E-mail enviado com sucesso' },
          { root: true }
        );
      })
      .catch((e) => {
        commit('emailFail');
        dispatch(
          'common/handleServiceException',
          { e, router },
          { root: true }
        );
      });
  },
};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  emailSuccess(state, response) {
    state.loading = false;
    state.error = false;
    state.alreadySent = true;
  },
  emailFail(state) {
    state.error = state;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
