import axios from 'axios';
import configService from '@/services/configService';

export default {
  async sendEmail(name, message, email, tel, company, referer) {
    return axios
      .post(`${configService.get('apiUrl')}/contact/send-email`, {
        name,
        message,
        email,
        tel,
        company,
        referer,
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        throw e;
      });
  },
};
