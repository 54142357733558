import _ from 'lodash';

const state = {
  footerInvisible: false,
};

const actions = {
  toggleFooter({ commit }, { footerInvisible }) {
    commit('toggleFooter', { footerInvisible });
  },
};

const mutations = {
  toggleFooter(state, { footerInvisible }) {
    state.footerInvisible = footerInvisible;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
