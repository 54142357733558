<template>
  <div id="features">
    <section class="text-gray-700 body-font">
      <div class="container px-5 py-12 mx-auto">
        <div
          class="flex flex-wrap w-full mb-20 flex-col items-center text-center"
        >
          <h1
            class="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900"
          >
            Proteção legal que cabe no seu bolso
          </h1>

          <p class="lg:w-1/2 w-full leading-relaxed text-base">
            Nossos documentos seguem todas as diretrizes pela LGPD, bem como as
            boas práticas de mercados nacionais e internacionais.
          </p>
        </div>
        <div class="flex flex-wrap -m-4">
          <div class="xl:w-1/3 md:w-1/2 p-4">
            <div class="border border-gray-300 p-6 rounded-lg">
              <div
                class="flex my-2 text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-gray-400 text-gray-800 rounded-sm"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  width="18"
                  class="mr-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                  />
                </svg>
                Para sites e Aplicativos
              </div>
              <h2 class="text-lg text-gray-900 font-medium title-font mb-2">
                Termos de uso
              </h2>
              <p class="leading-relaxed text-base">
                Confeccione Termos de Uso para seu site ou aplicativo a fim de
                resguardar sua empresa. Também possuímos uma opção de Termos de
                Uso utilizando os preceitos de customização para sua empresa.
              </p>
            </div>
          </div>
          <div class="xl:w-1/3 md:w-1/2 p-4">
            <div class="border border-gray-300 p-6 rounded-lg">
              <div
                class="flex my-2 text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-gray-400 text-gray-800 rounded-sm"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  width="18"
                  class="mr-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                  />
                </svg>
                Para sites e Aplicativos
              </div>
              <h2 class="text-lg text-gray-900 font-medium title-font mb-2">
                Política de Privacidade
              </h2>
              <p class="leading-relaxed text-base">
                Confeccione uma política de privacidade on-line para seu site ou
                aplicativo se adequarem à LGPD. Também possuímos uma opção de
                Política de Privacidade utilizando os preceitos de customização!
              </p>
            </div>
          </div>
          <div class="xl:w-1/3 md:w-1/2 p-4">
            <div class="border border-gray-300 p-6 rounded-lg">
              <div
                class="flex my-2 text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-gray-400 text-gray-800 rounded-sm"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  width="18"
                  class="mr-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                  />
                </svg>
                Para sites e Aplicativos
              </div>

              <h2 class="text-lg text-gray-900 font-medium title-font mb-2">
                Relatório de Impacto à Proteção de Dados
              </h2>
              <p class="leading-relaxed text-base">
                Nosso template de relatório de impacto lhe auxilia a identificar
                e minimizar os riscos de um projeto que envolva o tratamento de
                dados.
              </p>
            </div>
          </div>
          <div class="xl:w-1/3 md:w-1/2 p-4">
            <div class="border border-gray-300 p-6 rounded-lg">
              <div
                class="flex my-2 text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-gray-400 text-gray-800 rounded-sm"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  width="18"
                  class="mr-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                  />
                </svg>
                Para sites e Aplicativos
              </div>
              <h2 class="text-lg text-gray-900 font-medium title-font mb-2">
                Economize milhares de reais em documentos legais
              </h2>
              <p class="leading-relaxed text-base">
                Evite gastos extras e adeque sua empresa à LGPD por um custo
                simbólico.
              </p>
            </div>
          </div>
          <div class="xl:w-1/3 md:w-1/2 p-4">
            <div class="border border-gray-300 p-6 rounded-lg">
              <div
                class="flex my-2 text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-gray-400 text-gray-800 rounded-sm"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  width="18"
                  class="mr-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                  />
                </svg>
                Para sites e Aplicativos
              </div>
              <h2 class="text-lg text-gray-900 font-medium title-font mb-2">
                Política de Cookies
              </h2>
              <p class="leading-relaxed text-base">
                Use nossa ferramenta de política de cookies para confeccionar
                uma política de cookies, construir um aviso de cookies e
                gerenciar o consentimento do usuário.
              </p>
            </div>
          </div>
          <div class="xl:w-1/3 md:w-1/2 p-4">
            <div class="border border-gray-300 p-6 rounded-lg">
              <div
                class="flex my-2 text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-gray-400 text-gray-800 rounded-sm"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  width="18"
                  class="mr-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                  />
                </svg>
                Para sites, aplicativos e organizações
              </div>
              <h2 class="text-lg text-gray-900 font-medium title-font mb-2">
                Política de Privacidade Para Colaboradores
              </h2>
              <p class="leading-relaxed text-base">
                Utilize nossa ferramenta para se resguardar e explicar a seus
                colaboradores como é realizado o tratamento dos dados.
              </p>
            </div>
          </div>
          <div class="xl:w-1/3 md:w-1/2 p-4">
            <div class="border border-gray-300 p-6 rounded-lg">
              <div
                class="flex my-2 text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-gray-400 text-gray-800 rounded-sm"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  width="18"
                  class="mr-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                  />
                </svg>
                Para organizações internas
              </div>
              <h2 class="text-lg text-gray-900 font-medium title-font mb-2">
                Aviso de Privacidade Interno
              </h2>
              <p class="leading-relaxed text-base">
                Com o nosso serviço de confecção de Aviso de Privacidade
                Interno, uniformize o entendimento em Privacidade e Proteção de
                Dados entre os colaboradores de sua empresa e mitigue riscos.
              </p>
            </div>
          </div>
          <div class="xl:w-1/3 md:w-1/2 p-4">
            <div class="border border-gray-300 p-6 rounded-lg">
              <div
                class="flex my-2 text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-gray-400 text-gray-800 rounded-sm"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  width="18"
                  class="mr-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                  />
                </svg>
                Para organizações internas
              </div>
              <h2 class="text-lg text-gray-900 font-medium title-font mb-2">
                Plano de Resposta a Incidentes
              </h2>
              <p class="leading-relaxed text-base">
                O template de Plano de Resposta a Incidentes lhe auxiliará a
                responder a esses eventos com assertividade a agilidade para que
                não impactem negativamente o nome de sua empresa.
              </p>
            </div>
          </div>
          <div class="xl:w-1/3 md:w-1/2 p-4">
            <div class="border border-gray-300 p-6 rounded-lg">
              <div
                class="flex my-2 text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-gray-400 text-gray-800 rounded-sm"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  width="18"
                  class="mr-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                  />
                </svg>
                Para organizações internas
              </div>
              <h2 class="text-lg text-gray-900 font-medium title-font mb-2">
                Planilha de Registro de Operações
              </h2>
              <p class="leading-relaxed text-base">
                Conforme o artigo 37 da LGPD, é obrigatório que os agentes de
                tratamento possuam um registro de operações. Para tanto,
                fornecemos o documento para o mapeamento do fluxo de dados das
                atividades de sua empresa.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Features',
};
</script>

<style scoped></style>
