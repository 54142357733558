<template>
  <div>
    <transition name="fade">
      <div
        class="z-50 transition duration-500 fixed bottom-0 inset-x-0 pb-2 sm:pb-5"
        v-if="isBannerOpen && scrollpx > 1900"
      >
        <div class="max-w-screen-xl mx-auto px-2 sm:px-6 lg:px-8">
          <div class="p-2 rounded-lg bg-indigo-600 shadow-lg sm:p-3">
            <div class="flex items-center justify-between flex-wrap">
              <div class="w-0 flex-1 flex items-center">
                <span class="flex p-2 rounded-lg bg-indigo-800">
                  <svg
                    class="h-6 w-6 text-white"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                    />
                  </svg>
                </span>
                <p class="ml-3 font-medium text-white truncate">
                  <span class="md:hidden"> Estamos anunciando um novo produto! </span>
                  <span class="hidden md:inline">
                    Ótimas noticias! Estamos anunciando pacotes de revenda de serviço com preços
                    especiais!
                  </span>
                </p>
              </div>
              <div class="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                <div class="rounded-md shadow-sm">
                  <a
                    href="#"
                    class="flex items-center justify-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-indigo-600 bg-white hover:text-indigo-500 focus:outline-none focus:shadow-outline transition ease-in-out duration-150"
                  >
                    Conheça Sobre
                  </a>
                </div>
              </div>
              <div class="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
                <button
                  type="button"
                  class="-mr-1 flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:bg-indigo-500 transition ease-in-out duration-150"
                  @click="isBannerOpen = false"
                >
                  <svg
                    class="h-6 w-6 text-white"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                    @click="isBannerOpen = false"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <nav class="bg-indigo-700 z-50" style="">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex items-center justify-between h-14">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <router-link to="/" class="">
                <img src="../../../assets/img/logo-easytermos.svg" alt="" class="h-8" />
              </router-link>
            </div>
          </div>
          <div class="flex"></div>
          <div class="hidden md:block">
            <div class="ml-4 flex items-center md:ml-6">
              <a
                href="#"
                class="text-base mr-3 leading-6 font-medium text-white hover:text-gray-300 focus:outline-none focus:text-gray-300 transition ease-in-out duration-150"
              >
                Preços
              </a>

              <a
                href="#"
                class="text-base leading-6 font-medium text-white mr-3 hover:text-gray-300 focus:outline-none focus:text-gray-300 transition ease-in-out duration-150"
              >
                O que é LGPD?
              </a>

              <a
                href="#"
                class="text-base leading-6 font-medium text-white mr-3 hover:text-gray-300 focus:outline-none focus:text-gray-300 transition ease-in-out duration-150 flex align-middle"
                @click="isOpenMenu = !isOpenMenu"
              >
                <span class="flex align-middle align-content-center"
                  >Serviços
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    class="w-5 h-5 align-self-center mt-1 ml-2"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 9l-7 7-7-7"
                    /></svg
                ></span>
              </a>
              <a
                href="#"
                class="text-base leading-6 font-medium text-white mr-3 hover:text-gray-300 focus:outline-none focus:text-gray-300 transition ease-in-out duration-150"
              >
                Criar documentos
              </a>

              <button
                class="p-1 border-2 border-transparent text-gray-400 rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-gray-700"
                aria-label="Notifications"
              >
                <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                  />
                </svg>
              </button>

              <!-- Profile dropdown -->
              <div class="ml-3 relative">
                <div>
                  <button
                    v-if="isOpen"
                    @click="isOpen = false"
                    class="fixed top-0 right-0 bottom-0 left-0 h-full w-full bg-transparent cursor-default"
                  ></button>
                  <button
                    class="max-w-xs flex items-center text-sm rounded-full text-white focus:outline-none focus:shadow-solid"
                    id="user-menu"
                    aria-label="User menu"
                    aria-haspopup="true"
                    @click="isOpen = !isOpen"
                  >
                    <img class="h-8 w-8 rounded-full bg-white" alt="" />
                  </button>
                </div>

                <div class="origin-top-right absolute right-0 mt-2 w-48 z-50 rounded-md shadow-lg">
                  <div
                    class="py-1 rounded-md bg-white shadow-xs z-50"
                    role="menu"
                    aria-orientation="vertical"
                    v-if="isOpen"
                    aria-labelledby="user-menu"
                  >
                    <a
                      href="#"
                      class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                      >Your Profile</a
                    >

                    <a
                      href="#"
                      class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                      >Settings</a
                    >

                    <a
                      href="#"
                      class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                      >Sign out</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="-mr-2 flex md:hidden">
            <!-- Mobile menu button -->
            <button
              class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white"
            >
              <!-- Menu open: "hidden", Menu closed: "block" -->
              <svg class="block h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              <!-- Menu open: "block", Menu closed: "hidden" -->
              <svg class="hidden h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <!--
      Mobile menu, toggle classes based on menu state.

      Open: "block", closed: "hidden"
    -->
      <div
        class="z-50 absolute right-20 mt-3 transform w-screen max-w-md lg:max-w-3xl"
        v-if="isOpenMenu"
      >
        <div class="rounded-lg shadow-lg">
          <div class="rounded-lg shadow-xs overflow-hidden">
            <div class="z-20 relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
              <a
                href="#"
                class="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
              >
                <div
                  class="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12"
                >
                  <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                    />
                  </svg>
                </div>
                <div class="space-y-1">
                  <p class="text-base leading-6 font-medium text-gray-900">O que é LGPD?</p>
                  <p class="text-sm leading-5 text-gray-500">Aprenda sobre a nova lei etc etc</p>
                </div>
              </a>
              <a
                href="#"
                class="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
              >
                <div
                  class="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12"
                >
                  <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                    />
                  </svg>
                </div>
                <div class="space-y-1">
                  <p class="text-base leading-6 font-medium text-gray-900">Documentos gerados</p>
                  <p class="text-sm leading-5 text-gray-500">
                    Speak directly to your customers in a more meaningful way.
                  </p>
                </div>
              </a>
              <a
                href="#"
                class="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
              >
                <div
                  class="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12"
                >
                  <svg
                    class="h-6 w-6"
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                    />
                  </svg>
                </div>
                <div class="space-y-1">
                  <p class="text-base leading-6 font-medium text-gray-900">Ajuda</p>
                  <p class="text-sm leading-5 text-gray-500">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Porro rerum veritatis,
                    eum beatae
                  </p>
                </div>
              </a>
              <a
                href="#"
                class="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
              >
                <div
                  class="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12"
                >
                  <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z"
                    />
                  </svg>
                </div>
                <div class="space-y-1">
                  <p class="text-base leading-6 font-medium text-gray-900">Blog</p>
                  <p class="text-sm leading-5 text-gray-500">
                    Connect with third-party tools that you�re already using.
                  </p>
                </div>
              </a>
            </div>
            <div class="p-5 bg-gray-50 sm:p-8">
              <a
                href="#"
                class="-m-3 p-3 flow-root space-y-1 rounded-md hover:bg-gray-100 transition ease-in-out duration-150"
              >
                <div class="flex items-center space-x-3">
                  <div class="text-base leading-6 font-medium text-gray-900">
                    Revenda para escritórios
                  </div>
                  <span
                    class="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium leading-5 bg-indigo-100 text-indigo-800"
                  >
                    Novo
                  </span>
                </div>
                <p class="text-sm leading-5 text-gray-500">
                  Revenda nossos serviços com custo reduzido
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="hidden md:hidden">
        <div class="pt-4 pb-3 border-t border-gray-700">
          <div class="flex items-center px-5 space-x-3">
            <div class="flex-shrink-0">
              <img
                class="h-10 w-10 rounded-full"
                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                alt=""
              />
            </div>
            <div class="space-y-1">
              <div class="text-base font-medium leading-none text-white">Tom Cook</div>
              <div class="text-sm font-medium leading-none text-gray-400">tom@example.com</div>
            </div>
          </div>

          <div class="mt-3 px-2 space-y-1">
            <a
              href="#"
              class="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
              >Your Profile</a
            >

            <a
              href="#"
              class="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
              >Settings</a
            >

            <a
              href="#"
              class="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
              >Sign out</a
            >
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "NavBar",
  data: () => ({
    isOpen: false,
    isBannerOpen: true,
    scrollpx: false,
    isOpenMenu: false,
    time: false,
  }),
  computed: {
    ...mapState("auth", ["user"]),
    ...mapGetters("auth", ["isLoggedIn"]),
  },
  methods: {
    handleScroll() {
      this.scrollpx = window.scrollY;
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);

    setTimeout(function delay() {
      this.time = true;
    }, 1500);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active em versões anteriores a 2.1.8 */ {
  opacity: 0;
}
</style>
