<template>
  <div class="fixed top-0 w-full z-50">
    <transition name="">
      <div
        class="relative bg-coYellow-500 z-50"
        v-if="isBannerOpen && scrollpx < 1000 && page == 'home'"
      >
        <div class="max-w-screen-xl mx-auto py-3 px-3 sm:px-6 lg:px-8 z-50">
          <div class="pr-16 sm:text-center sm:px-16">
            <p class="text-sm text-black">
              <span class="md:hidden"> 25% de desconto virada de ano! </span>
              <span class="hidden md:inline">
                <span class="font-bold">Promoção de começo de ano</span> | 25%
                de desconto por tempo limitado. Aproveite nosso pacote completo
                de compliance até 30 de março.
                <br />
                {{
                  days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's '
                }}
              </span>
              <span class="w-full block sm:inline-block">
                <a href="/app" class="text-black font-bold underline">
                  Comprar &rarr;
                </a>
              </span>
            </p>
          </div>
          <div
            class="absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start"
          >
            <p
              type=""
              @click="isBannerOpen = !isBannerOpen"
              class="cursor-pointer flex hover:bg-transparent focus:bg-transparent text-black p-2 bg-transparent focus:outline-none transition ease-in-out duration-150"
            >
              <svg
                class="h-6 w-6 text-white"
                stroke="#000"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </p>
          </div>
        </div>
      </div>
    </transition>
    <div
      v-if="home === 'easyTermos' && isLoggedIn() === false && showLoginModal"
    >
      <login-box-e-t></login-box-e-t>
    </div>
    <div
      v-else-if="
        home !== 'easyTermos' && isLoggedIn() === false && showLoginModal
      "
    >
      <login-box-c-o></login-box-c-o>
    </div>
    <header class="z-30 w-full px-2 py-4 bg-co-500 sm:px-4">
      <div class="container flex items-center justify-between mx-auto">
        <div class="flex-shrink-0">
          <router-link to="/" class="">
            <img
              src="../../../assets/img/logo-complianceonline.svg"
              alt=""
              class="h-4"
            />
          </router-link>
        </div>
        <div class="relative hidden space-x-1"></div>
        <div class="flex items-center space-x-1">
          <div class="relative hidden sm:inline-flex">
            <p
              class="flex items-center rounded-full btn btn-sm text-white"
              @click="one = !one"
              v-click-outside="toggle"
            >
              Produtos e Serviços
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
                class="flex-none w-4 h-4 ml-1 -mr-1 transition duration-200 ease-out transform"
                :class="{ 'rotate-180': one }"
              >
                <polyline points="6 9 12 15 18 9"></polyline>
              </svg>
            </p>
            <div
              class="absolute top-0 z-50 w-screen max-w-md p-2 mx-0 my-12 text-sm text-gray-800 transform bg-white rounded shadow lg:left-1/2 lg:-translate-x-1/2"
              v-if="one"
            >
              <div class="grid grid-cols-2 gap-2 mb-2">
                <a
                  href="/pagina/adequar-empresa-lgpd"
                  class="px-3 py-2 transition rounded hover:bg-gray-200 hover:text-yellow-600"
                >
                  <p class="font-semibold">adequar minha empresa à LGPD</p>
                  Passos para organizar sua empresa a LGPD.
                </a>
                <a
                  href="/pagina/o-que-e-lgpd"
                  class="px-3 py-2 transition rounded hover:bg-gray-200 hover:text-yellow-600"
                >
                  <p class="font-semibold">Comprar política de privacidade</p>
                  Crie sua política de privacidade e cookies em minutos.
                </a>
                <a
                  href="/pagina/gerador-de-termos-de-uso"
                  class="px-3 py-2 transition rounded hover:bg-gray-200 hover:text-yellow-600"
                >
                  <p class="font-semibold">Comprar termos de uso</p>
                  Crie seus termos e condições em minutos e com preço acessível.
                </a>
                <a
                  href="/pagina/guia-de-adequacao-lgpd"
                  class="px-3 py-2 transition rounded hover:bg-gray-200 hover:text-yellow-600"
                >
                  <p class="font-semibold">Guia de adequação a LGPD</p>
                  Nossa solução permite que você documente facilmente todas as
                  atividades de tratamento de dados em sua organização.
                </a>
              </div>
              <!--              <div class="px-3 py-2 w-full">-->
              <!--                <a-->
              <!--                  href="/pagina/modelo-lgpd"-->
              <!--                  class="px-3 py-2 w-full hover:text-gray-500"-->
              <!--                >-->
              <!--                  <p class="font-semibold flex">-->
              <!--                    <svg-->
              <!--                      xmlns="http://www.w3.org/2000/svg"-->
              <!--                      fill="none"-->
              <!--                      viewBox="0 0 24 24"-->
              <!--                      stroke="currentColor"-->
              <!--                      width="18"-->
              <!--                      class="mr-1"-->
              <!--                    >-->
              <!--                      <path-->
              <!--                        stroke-linecap="round"-->
              <!--                        stroke-linejoin="round"-->
              <!--                        stroke-width="2"-->
              <!--                        d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"-->
              <!--                      /></svg-->
              <!--                    >Modelo LGPD-->
              <!--                  </p>-->
              <!--                </a>-->
              <!--                <a-->
              <!--                  href="/pagina/modelo-politica-de-privacidade"-->
              <!--                  class="px-3 py-2 w-full hover:text-gray-500"-->
              <!--                >-->
              <!--                  <p class="font-semibold flex">-->
              <!--                    <svg-->
              <!--                      xmlns="http://www.w3.org/2000/svg"-->
              <!--                      fill="none"-->
              <!--                      viewBox="0 0 24 24"-->
              <!--                      stroke="currentColor"-->
              <!--                      width="18"-->
              <!--                      class="mr-1"-->
              <!--                    >-->
              <!--                      <path-->
              <!--                        stroke-linecap="round"-->
              <!--                        stroke-linejoin="round"-->
              <!--                        stroke-width="2"-->
              <!--                        d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"-->
              <!--                      />-->
              <!--                    </svg>-->
              <!--                    Modelo Política de Privacidade-->
              <!--                  </p>-->
              <!--                </a>-->
              <!--                <a-->
              <!--                  href="/pagina/modelo-de-termos-de-uso"-->
              <!--                  class="px-3 py-2 w-full hover:text-gray-500"-->
              <!--                >-->
              <!--                  <p class="font-semibold w-full flex">-->
              <!--                    <svg-->
              <!--                      xmlns="http://www.w3.org/2000/svg"-->
              <!--                      fill="none"-->
              <!--                      viewBox="0 0 24 24"-->
              <!--                      stroke="currentColor"-->
              <!--                      width="18"-->
              <!--                      class="mr-1"-->
              <!--                    >-->
              <!--                      <path-->
              <!--                        stroke-linecap="round"-->
              <!--                        stroke-linejoin="round"-->
              <!--                        stroke-width="2"-->
              <!--                        d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"-->
              <!--                      />-->
              <!--                    </svg>-->
              <!--                    Modelo de Termos de uso-->
              <!--                  </p>-->
              <!--                </a>-->
              <!--              </div>-->

              <a
                href="/artigos"
                class="flex items-center justify-center p-3 font-medium transition bg-gray-200 rounded text-yellow-600 hover:bg-gray-300"
              >
                Ver todos os artigos
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  aria-hidden="true"
                  class="w-4 h-4 ml-1"
                >
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline>
                </svg>
              </a>
            </div>
          </div>

          <div class="relative hidden sm:inline-flex">
            <a href="/artigos" class="text-white text-sm pl-3"> Artigos </a>
          </div>
          <a
            href="/pagina/programa-de-parceiros"
            class="text-white text-sm pl-3"
            >Pacotes Empresariais</a
          >
          <a href="/app" class="text-white text-sm pl-3 pr-3"
            >Meus Documentos</a
          >
          <router-link
            tag="p"
            to="/login"
            v-if="isLoggedIn() === false"
            class="cursor-pointer hidden rounded-full btn btn-sm text-white md:inline-flex"
          >
            Entrar
          </router-link>

          <div class="ml-3 relative" v-if="isLoggedIn() === true">
            <div>
              <button
                class="text-base leading-6 font-medium text-white mr-3 hover:text-gray-300 focus:outline-none focus:text-gray-300 transition ease-in-out duration-150"
                id="user-menu"
                aria-label="User menu"
                aria-haspopup="true"
                @click="isOpen = !isOpen"
                v-click-outside="toggleMenuIsOpen"
              >
                {{ user.first_name }}
              </button>
            </div>

            <div
              class="origin-top-right absolute right-0 mt-2 w-48 z-50 rounded-md shadow-lg"
            >
              <div
                class="py-1 rounded-md bg-white shadow-xs z-50"
                role="menu"
                aria-orientation="vertical"
                v-if="isOpen"
                aria-labelledby="user-menu"
              >
                <router-link
                  to="/app"
                  class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  role="menuitem"
                  >Minha Conta</router-link
                >

                <router-link
                  to="/logout"
                  class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  role="menuitem"
                  >Sair</router-link
                >
              </div>
            </div>
          </div>

          <div class="inline-flex md:hidden">
            <button
              class="flex-none px-2 btn btn-white btn-sm"
              @click="isOpenMenu = true"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
                class="w-5 h-5"
              >
                <line x1="3" y1="12" x2="21" y2="12"></line>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <line x1="3" y1="18" x2="21" y2="18"></line>
              </svg>
              <span class="sr-only">Open Menu</span>
            </button>
            <div
              class="absolute top-0 left-0 right-0 z-50 flex flex-col p-2 m-2 space-y-1 bg-white rounded shadow"
              @click="isOpenMenu = false"
              v-click-outside="toggleMenu"
              v-if="isOpenMenu"
            >
              <button
                class="self-end flex-none px-2 btn btn-link btn-icon"
                @click="open = false"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  aria-hidden="true"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
                <span class="sr-only">Close Menu</span>
              </button>
              <div class="grid grid-cols-2 gap-1">
                <a
                  href="/#pricing"
                  class="px-3 py-2 transition rounded hover:bg-gray-200 hover:text-yellow-600"
                  >Preços {{ scrollpx }}</a
                >
                <a href="/artigos" class="px-3 py-2">Blog</a>
                <span
                  v-if="isLoggedIn() === false"
                  @click="showLoginModal = !showLoginModal"
                  class="px-3 py-2 transition rounded hover:bg-gray-200 hover:text-yellow-600"
                  >Entrar</span
                >
                <div v-else>
                  <router-link
                    tag="button"
                    to="/app"
                    class="text-base leading-6 font-medium text-white mr-3 hover:text-gray-300 focus:outline-none focus:text-gray-300 transition ease-in-out duration-150"
                    id="user-menu"
                    aria-label="User menu"
                    aria-haspopup="true"
                    @click="isOpen = !isOpen"
                  >
                    {{ user.first_name }}
                  </router-link>
                </div>
              </div>
              <p
                class="pt-2 pl-3 text-xs font-semibold tracking-wide text-gray-500 uppercase"
              >
                Produtos
              </p>
              <div class="grid grid-cols-2 gap-1">
                <a
                  href="/pagina/adequar-empresa-lgpd"
                  class="px-3 py-2 transition rounded hover:bg-gray-200 hover:text-yellow-600"
                >
                  adequar minha empresa à LGPD
                </a>
                <!--                <a-->
                <!--                  href="/pagina/o-que-e-lgpd"-->
                <!--                  class="px-3 py-2 transition rounded hover:bg-gray-200 hover:text-yellow-600"-->
                <!--                >-->
                <!--                  Comprar política de privacidade-->
                <!--                </a>-->
                <a
                  href="/pagina/gerador-de-termos-de-uso"
                  class="px-3 py-2 transition rounded hover:bg-gray-200 hover:text-yellow-600"
                >
                  Comprar termos de uso
                </a>
                <!--                <a-->
                <!--                  href="/pagina/guia-de-adequacao-lgpd"-->
                <!--                  class="px-3 py-2 transition rounded hover:bg-gray-200 hover:text-yellow-600"-->
                <!--                >-->
                <!--                  Guia de adequação a LGPD-->
                <!--                </a>-->
              </div>
              <a
                href="/#features"
                class="flex items-center justify-center p-3 font-medium transition bg-gray-200 rounded text-yellow-600 hover:bg-gray-300"
              >
                Todas as vantagens
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  aria-hidden="true"
                  class="w-4 h-4 ml-1"
                >
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { required, minLength } from 'vuelidate/lib/validators';
import router from '@/router';
import LoginBoxET from '@/components/Home/HomeET/LoginBox.vue';
import LoginBoxCO from '@/components/Home/HomeCO/LoginBox.vue';

import Vue from 'vue';
import ClickOutside from 'vue-click-outside';

export default {
  name: 'NavBar',
  components: {
    LoginBoxET,
    LoginBoxCO,
  },
  data: () => ({
    isOpen: false,
    isBannerOpen: true,
    open: true,
    isOpenMenu: false,
    one: false,
    showLoginModal: false,
    days: '',
    hours: '',
    minutes: '',
    page: '',
    scrollpx: 0,
    seconds: '',
    home: '',
    form: {
      username: '',
      password: '',
    },
    time: false,
  }),
  validations: {
    form: {
      username: {
        required,
      },
      password: {
        required,
        minLength: minLength(6),
      },
    },
  },
  computed: {
    ...mapGetters('alert', ['errorMessages', 'successMessages']),
    ...mapState('auth', ['user', 'loading']),
    ...mapGetters('auth', ['isLoggedIn']),
  },
  methods: {
    ...mapActions('auth', ['login', 'logout']),
    toggle() {
      this.one = false;
    },
    toggleMenuIsOpen() {
      this.isOpen = false;
    },
    toggleMenu() {
      this.isOpenMenu = false;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      // Form submit logic
      this.login({
        username: this.form.username,
        password: this.form.password,
        router,
      });
    },
    handleScroll() {
      this.scrollpx = window.scrollY;
    },
  },
  created() {
    this.isOpenMenu = false;

    window.addEventListener('scroll', this.handleScroll);

    setTimeout(function delay() {
      this.time = true;
    }, 1500);

    var countDownDate = new Date('Mar 30, 2021 12:00:00').getTime();

    setInterval(() => {
      var now = new Date().getTime();

      this.page = this.$route.name;
      var distance = countDownDate - now;

      this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
      this.hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        clearInterval(distance);
      }
    }, 1000);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active em versões anteriores a 2.1.8 */ {
  opacity: 0;
}
</style>
