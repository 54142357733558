<template>
  <div :class="{ hidden: navInvisible }" class="fixed top-0 w-full z-50">
    <transition name="">
      <div
        class="relative hidden bg-et-500 z-50"
        v-if="isBannerOpen && scrollpx < 1000 && page == 'home'"
      >
        <div class="max-w-screen-xl mx-auto py-3 px-3 sm:px-6 lg:px-8 z-50">
          <div class="pr-16 sm:text-center sm:px-16">
            <p class="text-sm text-white">
              <span class="md:hidden"> 50% de desconto no carrinho </span>
              <span class="hidden md:inline">
                <span class="font-bold">Promoção Exclusiva</span> | 10% de
                desconto por tempo limitado com o cupom <b>LGPD10</b> aplicado
                no carrinho. Aproveite nosso pacote completo de LGPD até 30/04.
                <br />
                {{
                  days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's '
                }}
              </span>
              <span class="w-full block sm:inline-block">
                <a href="/app" class="text-white font-bold underline">
                  Comprar &rarr;
                </a>
              </span>
            </p>
          </div>
          <div
            class="absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start"
          >
            <p
              type=""
              @click="isBannerOpen = !isBannerOpen"
              class="cursor-pointer flex hover:bg-transparent focus:bg-transparent text-black p-2 bg-transparent focus:outline-none transition ease-in-out duration-150"
            >
              <svg
                class="h-6 w-6 text-white"
                stroke="#000"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </p>
          </div>
        </div>
      </div>
    </transition>
    <div
      v-if="home === 'easyTermos' && isLoggedIn() === false && showLoginModal"
    >
      <login-box-e-t></login-box-e-t>
    </div>
    <nav
      class="z-50 fixed w-full p-0 py-2"
      style=""
      :class="{
        'shadow-sm': scrollpx > 1000,
        'bg-white': scrollpx > 150,
        'z-30': scrollpx > 150,
      }"
    >
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-4">
        <div class="flex items-center justify-between h-14">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <router-link to="/" class="">
                <img
                  src="../../../assets/img/logo-easy-testes.svg"
                  alt=""
                  class="h-8"
                />
              </router-link>
            </div>
          </div>
          <div class="flex"></div>
          <div class="hidden md:block">
            <div class="ml-4 flex items-center md:ml-6">
              <!--              <a-->
              <!--                href="#"-->
              <!--                class="text-sm leading-6 font-medium text-gray-700 mr-10 hover:text-indigo-700 focus:outline-none focus:text-indigo-700 transition ease-in-out duration-150 flex align-middle"-->
              <!--                @click="isOpenMenu = !isOpenMenu"-->
              <!--              >-->
              <!--                <span class="flex align-middle align-content-center"-->
              <!--                  >Serviços-->
              <!--                  <svg-->
              <!--                    xmlns="http://www.w3.org/2000/svg"-->
              <!--                    fill="none"-->
              <!--                    viewBox="0 0 24 24"-->
              <!--                    class="w-5 h-5 align-self-center mt-1 ml-2"-->
              <!--                    stroke="currentColor"-->
              <!--                  >-->
              <!--                    <path-->
              <!--                      stroke-linecap="round"-->
              <!--                      stroke-linejoin="round"-->
              <!--                      stroke-width="2"-->
              <!--                      d="M19 9l-7 7-7-7"-->
              <!--                    /></svg-->
              <!--                ></span>-->
              <!--              </a>-->
              <div class="relative">
                <p
                  @click="isOpenMenu = !isOpenMenu"
                  class="text-sm cursor-pointer mr-10 leading-6 font-medium text-gray-700 hover:text-indigo-700 focus:outline-none focus:text-indigo-700 transition ease-in-out duration-150"
                >
                  Soluções
                </p>
                <div
                  class="z-50 absolute mt-3 transform w-screen md:max-w-md lg:max-w-md left-0 -ml-40 bg-white"
                  v-if="isOpenMenu"
                >
                  <div class="rounded-lg shadow-lg">
                    <div class="rounded-lg shadow-xs overflow-hidden">
                      <div
                        class="z-20 relative text-sm grid gap-6 bg-white px-2 py-3 sm:gap-5 sm:p-4"
                      >
                        <router-link
                          @click.native="isOpenMenu = false"
                          to="/politicas/politica-para-sites"
                          class="flex p-1 items-center hover:text-gray-700 hover:bg-green-100 rounded"
                        >
                          <div
                            class="w-6 h-6 flex items-center justify-center text-center rounded shadow-sm mr-2 bg-green-200"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              class="w-5 h-5 text-gray-800"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="4"
                                d="M5 13l4 4L19 7"
                              />
                            </svg>
                          </div>
                          Política de Privacidade para Sites
                        </router-link>
                        <router-link
                          @click.native="isOpenMenu = false"
                          to="/politicas/politica-para-aplicativos-mobile"
                          class="flex p-1 items-center hover:text-gray-700 hover:bg-red-100 rounded"
                        >
                          <div
                            class="w-6 h-6 flex items-center justify-center text-center rounded shadow-sm mr-2 bg-red-200"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              class="w-5 h-5 text-gray-800"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="4"
                                d="M5 13l4 4L19 7"
                              />
                            </svg>
                          </div>
                          Política de Privacidade para Aplicativos
                        </router-link>

                        <router-link
                          @click.native="isOpenMenu = false"
                          to="/politicas/politica-de-uso-para-saas"
                          class="flex p-1 items-center hover:text-gray-700 hover:bg-yellow-100 rounded"
                        >
                          <div
                            class="w-6 h-6 flex items-center justify-center text-center rounded shadow-sm mr-2 bg-yellow-200"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              class="w-5 h-5 text-gray-800"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="4"
                                d="M5 13l4 4L19 7"
                              />
                            </svg>
                          </div>
                          Política de Privacidade para SaaS
                        </router-link>

                        <router-link
                          @click.native="isOpenMenu = false"
                          to="/politicas/politica-de-uso-para-ecommerces"
                          class="flex p-1 items-center hover:text-gray-700 hover:bg-orange-100 rounded"
                        >
                          <div
                            class="w-6 h-6 flex items-center justify-center text-center rounded shadow-sm mr-2 bg-orange-200"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              class="w-5 h-5 text-gray-800"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="4"
                                d="M5 13l4 4L19 7"
                              />
                            </svg>
                          </div>
                          Política de Privacidade para E-commerce
                        </router-link>

                        <router-link
                          @click.native="isOpenMenu = false"
                          to="/politicas/politica-de-uso-para-blogs"
                          class="flex p-1 items-center hover:text-gray-700 hover:bg-purple-100 rounded"
                        >
                          <div
                            class="w-6 h-6 flex items-center justify-center text-center rounded shadow-sm mr-2 bg-purple-200"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              class="w-5 h-5 text-gray-800"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="4"
                                d="M5 13l4 4L19 7"
                              />
                            </svg>
                          </div>
                          Política de Privacidade para Blogs e Sites de Notícias
                        </router-link>

                        <router-link
                          @click.native="isOpenMenu = false"
                          to="/politicas/politica-de-uso-para-wordpress"
                          class="flex p-1 items-center hover:text-gray-700 hover:bg-yellow-100 rounded"
                        >
                          <div
                            class="w-6 h-6 flex items-center justify-center text-center rounded shadow-sm mr-2 bg-yellow-200"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              class="w-5 h-5 text-gray-800"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="4"
                                d="M5 13l4 4L19 7"
                              />
                            </svg>
                          </div>
                          Política de Privacidade para Wordpress
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <a
                href="/#pricing"
                class="text-sm mr-10 leading-6 font-medium text-gray-700 hover:text-indigo-700 focus:outline-none focus:text-indigo-700 transition ease-in-out duration-150"
              >
                Preços
              </a>

              <router-link
                to="/artigos"
                class="text-sm mr-10 leading-6 font-medium text-gray-700 hover:text-indigo-700 focus:outline-none focus:text-indigo-700 transition ease-in-out duration-150"
              >
                Blog
              </router-link>
              <router-link
                :to="{ path: '/ser-um-parceiro-easy-termos' }"
                class="text-sm mr-10 leading-6 font-medium text-gray-700 hover:text-indigo-700 focus:outline-none focus:text-indigo-700 transition ease-in-out duration-150"
              >
                Pacotes Empresariais
              </router-link>
              <router-link
                :to="{ path: '/login' }"
                v-if="isLoggedIn() === false"
                class="text-sm leading-6 font-medium text-gray-700 mr-10 hover:text-indigo-700 focus:outline-none focus:text-indigo-700 transition ease-in-out duration-150"
              >
                Meus Documentos
              </router-link>
              <router-link
                :to="{ path: '/app' }"
                v-if="isLoggedIn() === true"
                class="text-sm leading-6 font-medium text-gray-700 mr-10 hover:text-indigo-700 focus:outline-none focus:text-indigo-700 transition ease-in-out duration-150"
              >
                Meus Documentos
              </router-link>

              <!--  <router-link
                v-if="isLoggedIn() === false"
                :to="{ path: '/register' }"
                class="text-base leading-6 font-medium text-white mr-3 hover:text-gray-300 focus:outline-none focus:text-gray-300 transition ease-in-out duration-150"
              >
                Criar Conta
              </router-link> -->

              <router-link
                tag="p"
                to="/document/1/politicas-privacidade/MQ=="
                v-if="isLoggedIn() === false"
                class="rounded cursor-pointer shadow-lg py-2 sm:px-3 md:px-6 text-base leading-6 font-medium text-white mr-3 focus:outline-none transition ease-in-out duration-150 bg-et-500"
              >
                Começar sem custos!
              </router-link>

              <!-- Profile dropdown -->
              <div class="ml-3 relative" v-if="isLoggedIn() === true">
                <div>
                  <button
                    class="text-base leading-6 font-medium text-black mr-3 hover:text-gray-800 focus:outline-none focus:text-gray-800 transition ease-in-out duration-150"
                    id="user-menu"
                    aria-label="User menu"
                    aria-haspopup="true"
                    @click="isOpen = !isOpen"
                  >
                    Minha Conta
                  </button>
                </div>

                <div
                  v-if="isOpen"
                  @click="isOpen = false"
                  class="fixed w-screen h-screen inset-0 teste"
                  style="z-index: 1; background: transparent"
                />
                <div
                  class="origin-top-right absolute right-0 mt-2 w-48 z-50 rounded-md shadow-lg"
                >
                  <div
                    class="py-1 rounded-md bg-white shadow-xs z-50"
                    role="menu"
                    aria-orientation="vertical"
                    v-if="isOpen"
                    aria-labelledby="user-menu"
                  >
                    <div @click="isOpen = false">
                      <router-link
                        to="/minha-conta"
                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        role="menuitem"
                      >
                        Minha Conta
                      </router-link>
                    </div>

                    <div @click="isOpen = false">
                      <router-link
                        to="/logout"
                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        role="menuitem"
                      >
                        Sair
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="-mr-2 flex md:hidden items-center">
            <!-- Mobile menu button -->
            <router-link
              :to="{ path: '/app' }"
              v-if="isLoggedIn()"
              class="text-base leading-6 font-medium text-white mr-3 hover:text-gray-300 focus:outline-none focus:text-gray-300 transition ease-in-out duration-150"
            >
              Meus Documentos
            </router-link>
            <!--  <router-link
                v-if="isLoggedIn() === false"
                :to="{ path: '/register' }"
                class="text-base leading-6 font-medium text-white mr-3 hover:text-gray-300 focus:outline-none focus:text-gray-300 transition ease-in-out duration-150"
              >
                Criar Conta
              </router-link> -->

            <router-link
                to="/document/1/politicas-privacidade/MQ=="
              v-if="isLoggedIn() === false"
              class="bg-white rounded cursor-pointer py-1 sm:px-3 md:px-6 text-base leading-6 font-medium text-indigo-700 mr-3 hover:text-indigo-900 focus:outline-none focus:text-indigo-300 transition ease-in-out duration-150"
            >
              Começar sem custos!
            </router-link>
        
          </div>
        </div>
      </div>

      <!--
      Mobile menu, toggle classes based on menu state.

      Open: "block", closed: "hidden"
    -->
      <div
        v-if="isOpenMenu"
        @click="isOpenMenu = false"
        class="fixed w-screen h-screen inset-0"
        style="z-index: 1; background: transparent"
      />
    </nav>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { required, minLength } from 'vuelidate/lib/validators';
import router from '@/router';

import LoginBoxET from '@/components/Home/HomeET/LoginBox.vue';
import LoginBoxCO from '@/components/Home/HomeCO/LoginBox.vue';
import Vue from 'vue';

export default {
  name: 'NavBar',
  components: {
    LoginBoxET,
    LoginBoxCO,
  },
  data: () => ({
    isOpen: false,
    isBannerOpen: true,
    home: '',
    scrollpx: 0,

    isOpenMenu: false,
    showLoginModal: false,
    form: {
      username: '',
      password: '',
    },
    time: false,

    open: true,
    one: false,
    days: '',
    hours: '',
    minutes: '',
    page: '',
    seconds: '',
  }),
  validations: {
    form: {
      username: {
        required,
      },
      password: {
        required,
        minLength: minLength(6),
      },
    },
  },
  computed: {
    ...mapGetters('alert', ['errorMessages', 'successMessages']),
    ...mapState('auth', ['user', 'loading']),
    ...mapState('navbar', ['navInvisible']),
    ...mapGetters('auth', ['isLoggedIn']),
  },
  methods: {
    ...mapActions('auth', ['login', 'logout']),
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      // Form submit logic
      this.login({
        username: this.form.username,
        password: this.form.password,
        router,
      });
    },
    handleScroll() {
      this.scrollpx = window.scrollY;
    },
  },
  created() {
    this.isOpenMenu = false;

    window.addEventListener('scroll', this.handleScroll);

    setTimeout(function delay() {
      this.time = true;
    }, 1500);

    var countDownDate = new Date('Apr 29, 2021 12:00:00').getTime();

    setInterval(() => {
      var now = new Date().getTime();

      this.page = this.$route.name;
      var distance = countDownDate - now;

      this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
      this.hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        clearInterval(distance);
      }
    }, 1000);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active em versões anteriores a 2.1.8 */ {
  opacity: 0;
}
</style>
