<template>
  <div id="features ">
    <section class="text-gray-700 body-font">
      <div class="container px-5 py-15 mx-auto">
        <div
          class="flex flex-wrap w-full mb-24 flex-col items-center text-center"
        >
          <h2
            class="mt-4 max-w-3xl text-center text-xl font-extrabold text-gray-900 sm:mt-5 sm:leading-no leading-none ne sm:text-5xl lg:mt-6 lg:text-3xl xl:text-4xl"
          >
            Cuidamos da papelada para que você possa voltar ao trabalho
          </h2>

          <p class="text-xl max-w-3xl text-center text-gray-700 mt-6">
            Nossos documentos seguem todas as diretrizes pela LGPD, bem como as
            boas práticas de mercados nacionais e internacionais.
          </p>
        </div>
        <div class="flex text-center flex-wrap -m-4">
          <div class="xl:w-1/3 md:w-1/2 p-4">
            <div
              class="border border-gray-200 shadow-sm bg-white p-6 rounded-lg"
            >
              <h2 class="text-lg text-gray-900 font-medium title-font mb-2">
                Termos de uso
              </h2>
              <p class="leading-relaxed text-gray-600 text-md mt-3">
                Confeccione Termos de Uso para seu site ou aplicativo a fim de
                resguardar sua empresa. Também possuímos uma opção de Termos de
                Uso utilizando os preceitos de customização para sua empresa.
              </p>
            </div>
          </div>
          <div class="xl:w-1/3 md:w-1/2 p-4">
            <div
              class="border border-gray-200 shadow-sm bg-white p-6 rounded-lg"
            >
              <h2 class="text-lg text-gray-900 font-medium title-font mb-2">
                Política de Privacidade
              </h2>
              <p class="leading-relaxed text-gray-600 text-md mt-3">
                Confeccione uma política de privacidade on-line para seu site ou
                aplicativo se adequarem à LGPD. Também possuímos uma opção de
                Política de Privacidade utilizando os preceitos de customização!
              </p>
            </div>
          </div>
          <div class="xl:w-1/3 md:w-1/2 p-4">
            <div
              class="border border-gray-200 shadow-sm bg-white p-6 rounded-lg"
            >
              <h2 class="text-lg text-gray-900 font-medium title-font mb-2">
                Relatório de Impacto à Proteção de Dados
              </h2>
              <p class="leading-relaxed text-gray-600 text-md mt-3">
                Nosso template de relatório de impacto lhe auxilia a identificar
                e minimizar os riscos de um projeto que envolva o tratamento de
                dados.
              </p>
            </div>
          </div>
          <div class="xl:w-1/3 md:w-1/2 p-4">
            <div
              class="border border-gray-200 shadow-sm bg-white p-6 rounded-lg"
            >
              <h2 class="text-lg text-gray-900 font-medium title-font mb-2">
                Economize milhares de reais em documentos legais
              </h2>
              <p class="leading-relaxed text-gray-600 text-md mt-3">
                Evite gastos extras e adequar minha empresa à LGPDa agora mesmo
                as normas exigidas pela LGPD. Por um custo simbólico.
              </p>
            </div>
          </div>
          <div class="xl:w-1/3 md:w-1/2 p-4">
            <div
              class="border border-gray-200 shadow-sm bg-white p-6 rounded-lg"
            >
              <h2 class="text-lg text-gray-900 font-medium title-font mb-2">
                Política de Cookies
              </h2>
              <p class="leading-relaxed text-gray-600 text-md mt-3">
                Use nossa ferramenta de política de cookies para confeccionar
                uma política de cookies, construir um aviso de cookies e
                gerenciar o consentimento do usuário.
              </p>
            </div>
          </div>
          <div class="xl:w-1/3 md:w-1/2 p-4">
            <div
              class="border border-gray-200 shadow-sm bg-white p-6 rounded-lg"
            >
              <h2 class="text-lg text-gray-900 font-medium title-font mb-2">
                Política de Privacidade Para Colaboradores
              </h2>
              <p class="leading-relaxed text-gray-600 text-md mt-3">
                Utilize nossa ferramenta para se resguardar e explicar a seus
                colaboradores como é realizado o tratamento dos dados.
              </p>
            </div>
          </div>
          <div class="xl:w-1/3 md:w-1/2 p-4">
            <div
              class="border border-gray-200 shadow-sm bg-white p-6 rounded-lg"
            >
              <h2 class="text-lg text-gray-900 font-medium title-font mb-2">
                Aviso de Privacidade Interno
              </h2>
              <p class="leading-relaxed text-gray-600 text-md mt-3">
                Com o nosso serviço de confecção de Aviso de Privacidade
                Interno, uniformize o entendimento em Privacidade e Proteção de
                Dados entre os colaboradores de sua empresa e mitigue riscos.
              </p>
            </div>
          </div>
          <div class="xl:w-1/3 md:w-1/2 p-4">
            <div
              class="border border-gray-200 shadow-sm bg-white p-6 rounded-lg"
            >
              <h2 class="text-lg text-gray-900 font-medium title-font mb-2">
                Plano de Resposta a Incidentes
              </h2>
              <p class="leading-relaxed text-gray-600 text-md mt-3">
                O template de Plano de Resposta a Incidentes lhe auxiliará a
                responder a esses eventos com assertividade a agilidade para que
                não impactem negativamente o nome de sua empresa.
              </p>
            </div>
          </div>
          <div class="xl:w-1/3 md:w-1/2 p-4">
            <div
              class="border border-gray-200 shadow-sm bg-white p-6 rounded-lg"
            >
              <h2 class="text-lg text-gray-900 font-medium title-font mb-2">
                Planilha de Registro de Operações
              </h2>
              <p class="leading-relaxed text-gray-600 text-md mt-3">
                Conforme o artigo 37 da LGPD, é obrigatório que os agentes de
                tratamento possuam um registro de operações. Para tanto,
                fornecemos o documento para o mapeamento do fluxo de dados das
                atividades de sua empresa.
              </p>
            </div>
          </div>
          <Btn page="/app" text="Começar sem custos" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Btn from '@/components/Home/HomeET/Btn';

export default {
  name: 'Features',
  components: { Btn },
};
</script>

<style scoped></style>
