import axios from 'axios';
import {
  KEY_STRIPE_CO,
  ID_PLAN_CO,
  KEY_STRIPE_EASY,
} from '@/utils/variables.json';
import configService from '@/services/configService';
import { loadStripe } from '@stripe/stripe-js';

export default {
  async redirectToCheckoutCompliance() {
    const successUrl = `${location.origin}/app?status=success`;
    const cancelUrl = `${location.origin}/app`;

    const stripe = await loadStripe(KEY_STRIPE_CO);
    axios
      .post(`${configService.get('apiUrl')}/create-checkout-session`, {
        planId: ID_PLAN_CO,
        successUrl: successUrl,
        cancelUrl: cancelUrl,
      })
      .then(({ data: { id } }) => {
        return stripe.redirectToCheckout({ sessionId: id });
      })
      .catch((e) => {
        console.log(e);
        throw e;
      });
  },
  async redirectToCheckoutEasy(id_plan, customer_email) {
    const successUrl = `${location.origin}/app?status=success`;
    const cancelUrl = `${location.origin}/app`;

    const stripe = await loadStripe(KEY_STRIPE_EASY);
    axios
      .post(`${configService.get('apiUrl')}/create-checkout-session`, {
        planId: id_plan,
        successUrl: successUrl,
        cancelUrl: cancelUrl,
        customerEmail: customer_email,
      })
      .then(({ data: { id } }) => {
        return stripe.redirectToCheckout({ sessionId: id });
      })
      .catch((e) => {
        console.log(e);
        throw e;
      });
  },
};
