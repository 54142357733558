<template>
  <footer
    class="container mx-auto py-12 px-4"
    :class="{ hidden: footerInvisible }"
  >
    <div
      class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-12 gap-10 lg:gap-20 mb-3"
    >
      <div class="col-span-5">
        <a href="/" title="Ir para a home">
          <img
            class="bg-co-500 mb-3 p-2 w-1/2 rounded-lg"
            src="../../../assets/img/logo-complianceonline.svg"
            alt=""
          />
          <span class="sr-only">Easy Termos</span>
        </a>
        <a
          href="/pagina/adequar-empresa-lgpd"
          class="flex mb-3 md:mb-2 text-xs font-medium text-gray-600 hover:text-yellow-600 transition-colors duration-100 ease-in"
          >adequar minha empresa à LGPD</a
        >
        <a
          href="/pagina/o-que-e-lgpd"
          class="flex mb-3 md:mb-2 text-xs font-medium text-gray-600 hover:text-yellow-600 transition-colors duration-100 ease-in"
          >Comprar Política de Privacidade</a
        >
        <a
          href="/pagina/gerador-de-termos-de-uso"
          class="flex mb-3 md:mb-2 text-xs font-medium text-gray-600 hover:text-yellow-600 transition-colors duration-100 ease-in"
          >Gerador de Termos de Uso</a
        >
        <a
          href="/pagina/guia-de-adequacao-lgpd"
          class="flex mb-3 md:mb-2 text-xs font-medium text-gray-600 hover:text-yellow-600 transition-colors duration-100 ease-in"
          >Guia de adequação a LGPD</a
        >
        <!--        <a-->
        <!--          href="/pagina/modelo-lgpd"-->
        <!--          class="flex mb-3 md:mb-2 text-xs font-medium text-gray-600 hover:text-yellow-600 transition-colors duration-100 ease-in"-->
        <!--          >Modelo Completo LGPD</a-->
        <!--        >-->
        <!--        <a-->
        <!--          href="/pagina/modelo-politica-de-privacidade"-->
        <!--          class="flex mb-3 md:mb-2 text-xs font-medium text-gray-600 hover:text-yellow-600 transition-colors duration-100 ease-in"-->
        <!--          >Modelo Política de Privacidade</a-->
        <!--        >-->
        <!--        <a-->
        <!--          href="/pagina/modelo-de-termos-de-uso"-->
        <!--          class="flex mb-3 md:mb-2 text-xs font-medium text-gray-600 hover:text-yellow-600 transition-colors duration-100 ease-in"-->
        <!--          >Modelo de Termos de Uso</a-->
        <!--        >-->
      </div>
      <nav class="col-span-1 md:col-span-1 lg:col-span-2">
        <p
          class="uppercase text-gray-600 text-xs tracking-wider font-medium mb-3"
        >
          Produto
        </p>
        <a
          href="#features"
          class="flex mb-3 md:mb-2 text-sm font-medium text-gray-800 hover:text-yellow-600 transition-colors duration-100 ease-in"
          >Características</a
        >
        <a
          href="#pricing"
          class="flex mb-3 md:mb-2 text-sm font-medium text-gray-800 hover:text-yellow-600 transition-colors duration-100 ease-in"
          >Preços</a
        >
        <a
          href="/artigos"
          class="flex mb-3 md:mb-2 text-sm font-medium text-gray-800 hover:text-yellow-600 transition-colors duration-100 ease-in"
          >Artigos</a
        >
        <a
          href="/artigos"
          class="flex mb-3 md:mb-2 text-sm font-medium text-gray-800 hover:text-yellow-600 transition-colors duration-100 ease-in"
          >Perguntas Frequentes</a
        >
      </nav>

      <nav class="col-span-2 md:col-span-1 lg:col-span-2">
        <p
          class="uppercase text-gray-600 text-xs tracking-wider font-medium mb-3"
        >
          Contato
        </p>
        <a
          href="/contato"
          class="flex mb-3 md:mb-2 text-sm font-medium text-gray-800 hover:text-yellow-600 transition-colors duration-100 ease-in"
          >Página de Contato</a
        >
        <a
          href="/vagas"
          class="flex mb-3 md:mb-2 text-sm font-medium text-gray-800 hover:text-yellow-600 transition-colors duration-100 ease-in"
          >Trabalhe Conosco</a
        >
        <a
          href="https://www.instagram.com/easytermos/"
          class="flex mb-3 md:mb-2 text-sm font-medium text-gray-800 hover:text-yellow-600 transition-colors duration-100 ease-in"
          >Instagram</a
        >
        <a
          href="https://www.linkedin.com/company/easytermos/"
          class="flex mb-3 md:mb-2 text-sm font-medium text-gray-800 hover:text-yellow-600 transition-colors duration-100 ease-in"
          >Linkedin</a
        >
        <a
          href="https://www.facebook.com/easytermos"
          class="flex mb-3 md:mb-2 text-sm font-medium text-gray-800 hover:text-yellow-600 transition-colors duration-100 ease-in"
          >Facebook</a
        >

        <a
          href="mailto:dpo@complianceonline.com.br"
          class="flex mb-3 md:mb-2 text-sm font-medium text-gray-800 hover:text-yellow-600 transition-colors duration-100 ease-in"
          >DPO</a
        >
      </nav>
      <div class="col-span-3">
        <p
          class="uppercase text-gray-600 text-xs tracking-wider font-medium mb-3"
        >
          Se inscreva na nossa newsletter
        </p>
        <!-- Begin Mailchimp Signup Form -->
        <div id="mc_embed_signup">
          <form
            action="https://easytermos.us7.list-manage.com/subscribe/post?u=1d00fbaf85565990af8a824d6&amp;id=96a049fa0c"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            class="validate mb-2"
            target="_blank"
            novalidate
          >
            <div id="mc_embed_signup_scroll form-append">
              <div class="mc-field-group form-append">
                <input
                  type="email"
                  value=""
                  name="EMAIL"
                  class="required email form-input form-input-sm"
                  id="mce-EMAIL"
                  placeholder="E-mail"
                />
                <input
                  type="submit"
                  value="Inscrever"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  class="button btn btn-primary bg-co-500 btn-sm"
                />
              </div>
              <div id="mce-responses" class="clear">
                <div
                  class="response"
                  id="mce-error-response"
                  style="display: none"
                ></div>
                <div
                  class="response"
                  id="mce-success-response"
                  style="display: none"
                ></div>
              </div>
              <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
              <div style="position: absolute; left: -5000px" aria-hidden="true">
                <input
                  type="text"
                  name="b_1d00fbaf85565990af8a824d6_96a049fa0c"
                  tabindex="-1"
                  value=""
                />
              </div>
              <div class="clear"></div>
            </div>
          </form>
        </div>

        <!--End mc_embed_signup-->
        <p class="text-xs text-gray-600">
          Receba dicas, atualizações legislativas e muito conteúdo sobre a Lei
          Geral de Proteção de Dados e as melhores práticas para que sua empresa
          não seja afetada
        </p>
      </div>
    </div>
    <div
      class="flex flex-col md:flex-row justify-between items-start md:items-center border-t border-gray-200 pt-10 mt-10"
    >
      <p class="text-gray-700 font-medium text-sm text-left mb-6 md:mb-0">
        © Copyright 2020 Compliance Online 39.941.017/0001-89. Todos os direitos
        reservados.
        <br />
      </p>
      <div
        class="flex items-start md:items-center justify-start md:justify-center space-x-6"
      >
        <a
          href="/termos"
          class="font-medium text-sm text-gray-700 hover:text-yellow-600 transition-colors duration-100 ease-in"
          >Termos</a
        >
        <a
          href="/privacidade"
          class="font-medium text-sm text-gray-700 hover:text-yellow-600 transition-colors duration-100 ease-in"
          >Privacidade</a
        >
      </div>
    </div>
    <div class="mt-8">
      <span class="text-xs text-gray-600">
        O software, os materiais e a assistência fornecidos pelo
        complianceOnline têm como único objetivo auxiliar os usuários no
        cumprimento de seus requisitos legais. Em particular, os modelos
        fornecidos pelo complianceOnline são gerados automaticamente, mas cada
        palavra de nosso modelo foi escrita e continuamente revisada por uma
        equipe jurídica qualificada. No entanto, como pode ser facilmente
        compreendido, nada pode substituir uma consultoria jurídica profissional
        na elaboração da sua política de privacidade, política de cookies ou de
        qualquer outro documento legal ou procedimento de compliance. O nosso
        serviço faz o possível para lhe dar um ponto de partida, como um livro
        de modelos extremamente sofisticado, mas mesmo que nos esforcemos para
        prestar o melhor atendimento possível, não podemos garantir a
        conformidade com a lei, o que só um advogado pode fazer. Nada neste
        site, portanto, deve ser considerado conselho jurídico e nenhuma relação
        advogado-cliente é estabelecida. Observe que em alguns casos, dependendo
        de sua legislação, outras ações podem ser necessárias para tornar sua
        atividade compatível com a lei.
      </span>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'FooterBar',
  computed: {
    ...mapState('footer', ['footerInvisible']),
  },
};
</script>
