import _ from 'lodash';

import paginaService from '@/services/paginaService';

const state = {
  loading: false,
  error: null,
  post: {},
};

const actions = {
  getPagina({ commit }, { sistema, id }) {
    paginaService
      .getPagina(sistema, id)
      .then((response) => {
        response.Summary = response.Content.slice(0, 100);
        commit('getPaginaSuccess', response);
      })
      .catch((e) => {
        commit('getPaginaFailure', e);
      });
  },
};

const mutations = {
  getPaginaSuccess(state, response) {
    state.loading = false;
    state.post = response;
    state.error = false;
  },
  getPaginaFailure(state) {
    state.loading = false;
    state.error = state;
    state.post = {};
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
