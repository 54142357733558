<template>
  <section class="container mx-auto pt-0 pb-0 px-4">
    <section class="text-gray-700 body-font">
      <div class="container px-5 py-24 mx-auto">
        <div class="flex flex-col text-center w-full mb-20 items-center">
          <h2
            class="text-xs text-indigo-500 tracking-widest font-medium title-font mb-1"
          >
            SUA EMPRESA LEGALIZADA
          </h2>
          <h1
            class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900"
          >
            Quais são os principais requisitos para sites e proprietários de
            aplicativos?
          </h1>
        </div>
        <div class="flex flex-wrap">
          <div
            class="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200"
          >
            <h2
              class="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2"
            >
              Política de Privacidade
            </h2>
            <p class="leading-relaxed text-base mb-4">
              Forneça uma Política de Privacidade e Cookies que também inclua
              quaisquer provedores de serviços terceirizados que você esteja
              usando, como Google Analytics ou Google Adsense.
            </p>
            <a class="text-indigo-500 inline-flex items-center" href="/artigos"
              >Saiba Mais
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
          <div
            class="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200"
          >
            <h2
              class="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2"
            >
              Políticas de Cookies
            </h2>
            <p class="leading-relaxed text-base mb-4">
              Se você tiver utilizando dados dos usuários, deverá exibir um
              banner de cookie, fornecer uma política de cookies, obter
              consentimento para a instalação de cookies e bloquear os cookies
              de perfil até que o consentimento seja obtido.
            </p>
            <a class="text-indigo-500 inline-flex items-center" href="/artigos"
              >Saiba mais
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
          <div
            class="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200"
          >
            <h2
              class="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2"
            >
              Termos e Condições
            </h2>
            <p class="leading-relaxed text-base mb-4">
              Os Termos de Uso definem as regras de como seu site, aplicativo ou
              serviço deve ser usado e, o mais importante, protegem você de
              potenciais riscos.
            </p>
            <a class="text-indigo-500 inline-flex items-center" href="/artigos"
              >Saiba mais
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </section>
    <section class="text-gray-700 body-font">
      <div class="container px-5 py-24 mx-auto flex flex-wrap">
        <div
          class="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden shadow-lg rounded-lg"
        >
          <img
            alt="feature"
            class="object-contain object-center h-full w-full"
            src="@/assets/img/2.png"
          />
        </div>
        <div
          class="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center"
        >
          <div class="flex flex-col mb-10 lg:items-start items-center">
            <div class="flex-grow">
              <h2
                class="text-transparent bg-clip-text bg-gradient-to-r from-co-500 to-purple-700 text-lg title-font font-medium mb-3"
              >
                Documentos são gerados na mesma hora
              </h2>
              <p class="leading-relaxed text-base">
                Preencha todas as informações necessárias e nosso sistema de
                inteligencia artificial, construído e aprimorado por consultores
                legais fornecerá os documentos necessários para a sua empresa.
              </p>
            </div>
          </div>
          <div class="flex flex-col mb-10 lg:items-start items-center">
            <div class="flex-grow">
              <h2
                class="text-transparent bg-clip-text bg-gradient-to-r from-co-500 to-purple-700 text-lg title-font font-medium mb-3"
              >
                Economize milhares em custos legais
              </h2>
              <p class="leading-relaxed text-base">
                Quer deixar sua empresa nas normas da LGPD e não quer gastar
                milhares em serviços legais? Comece a utilizar os produtos da
                Compliance Online com produtos que encaixam no seu orçamento.
              </p>
            </div>
          </div>

          <div class="flex flex-col mb-10 lg:items-start items-center">
            <div class="flex-grow">
              <h2
                class="text-transparent bg-clip-text bg-gradient-to-r from-co-500 to-purple-700 text-lg title-font font-medium mb-3"
              >
                Foco no seu negócio, deixe-nos ajudá-lo com o compliance
              </h2>
              <p class="leading-relaxed text-base">
                A experiência de uma equipe jurídica internacional em uma
                solução de software conveniente e fácil de usar. Monitoramos
                continuamente as principais legislações e mantemos você e nossos
                produtos atualizados
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
export default {
  name: 'ContentCO',
};
</script>

<style scoped></style>
