<template>
  <section class="container mx-auto my-12 px-4 md:px-2">
    <section class="container px-4 pb-12 mx-auto md:px-2">
      <h1 class="mb-8 text-xl font-bold md:text-3xl">Perguntas Frequentes</h1>
      <div class="text-sm">
        <div x-data="collapse()" class="px-4 mb-3 bg-gray-100 rounded">
          <button
            x-spread="trigger"
            class="flex items-center justify-between w-full py-4 pr-1 font-semibold text-left text-gray-800 outline-none hover:text-yellow-900 focus:text-black focus:outline-none"
            role="button"
            aria-controls="faq4"
            aria-expanded="false"
          >
            O que a LGPD considera como dados pessoais?

            <svg
              :class="{ 'rotate-45': open }"
              class="flex-none w-4 h-4 ml-4 transition transform"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              aria-hidden="true"
            >
              <line x1="12" y1="5" x2="12" y2="19"></line>
              <line x1="5" y1="12" x2="19" y2="12"></line>
            </svg>
          </button>
          <div
            x-spread="collapse"
            x-cloak
            class="pb-5 pr-0 text-gray-700 md:pr-40"
            id="faq4"
          >
            Para a LGPD, dado pessoal é qualquer tipo de dado, seja em meio
            digital ou físico, que possa individualizar ou levar a identificação
            de alguém. Dado pessoal pode ser nome, dados financeiros,
            escolaridade, entre outros que, separados ou juntos, individualizam
            uma pessoa. Ainda, a LGPD conta com o conceito de dados pessoais
            sensíveis, que são toda informação que revele a raça, origem étnica,
            religião, preferência política/partidária, filiação sindical,
            condição de saúde, orientação sexual, biometria ou características
            genéticas de alguém.
          </div>
        </div>
        <div x-data="collapse()" class="px-4 mb-3 bg-gray-100 rounded">
          <button
            x-spread="trigger"
            class="flex items-center justify-between w-full py-4 pr-1 font-semibold text-left text-gray-800 outline-none hover:text-yellow-900 focus:text-black focus:outline-none"
            role="button"
            aria-controls="faq5"
            aria-expanded="false"
          >
            Como a Compliance Online me auxilia na adequação à LGPD?
            <svg
              :class="{ 'rotate-45': open }"
              class="flex-none w-4 h-4 ml-4 transition transform"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              aria-hidden="true"
            >
              <line x1="12" y1="5" x2="12" y2="19"></line>
              <line x1="5" y1="12" x2="19" y2="12"></line>
            </svg>
          </button>
          <div
            x-spread="collapse"
            x-cloak
            class="pb-5 pr-0 text-gray-700 md:pr-40"
            id="faq5"
          >
            A Compliance Online gera diversos documentos obrigatórios ou
            considerados boas práticas de mercado para a adequação de sua
            empresa à LGPD como Política de Privacidade, Termos de Uso, Política
            de Cookies, entre outros.
          </div>
        </div>
        <div x-data="collapse()" class="px-4 mb-3 bg-gray-100 rounded">
          <button
            x-spread="trigger"
            class="flex items-center justify-between w-full py-4 pr-1 font-semibold text-left text-gray-800 outline-none hover:text-yellow-900 focus:text-black focus:outline-none"
            role="button"
            aria-controls="faq6"
            aria-expanded="false"
          >
            Quem precisa se adequar à LGPD?
            <svg
              :class="{ 'rotate-45': open }"
              class="flex-none w-4 h-4 ml-4 transition transform"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              aria-hidden="true"
            >
              <line x1="12" y1="5" x2="12" y2="19"></line>
              <line x1="5" y1="12" x2="19" y2="12"></line>
            </svg>
          </button>
          <div
            x-spread="collapse"
            x-cloak
            class="pb-5 pr-0 text-gray-700 md:pr-40"
            id="faq6"
          >
            Qualquer pessoa, física ou jurídica (pública ou privada) que realize
            tratamento de dados pessoais. Portanto, a LGPD não se destina apenas
            a quem trata dados coletados na internet, mas a qualquer operação de
            tratamento de dados pessoais que tenham sido coletados dentro do
            território brasileiro ou que tenha como objetivo oferecer bens ou
            serviços a pessoas localizadas no Brasil, independentemente destes
            dados pessoais terem sido coletados offline ou online, em meios
            físicos ou digitais.
          </div>
        </div>
        <div x-data="collapse()" class="px-4 mb-3 bg-gray-100 rounded">
          <button
            x-spread="trigger"
            class="flex items-center justify-between w-full py-4 pr-1 font-semibold text-left text-gray-800 outline-none hover:text-yellow-900 focus:text-black focus:outline-none"
            role="button"
            aria-controls="faq6"
            aria-expanded="false"
          >
            O que é tratamento de dados pessoais?

            <svg
              :class="{ 'rotate-45': open }"
              class="flex-none w-4 h-4 ml-4 transition transform"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              aria-hidden="true"
            >
              <line x1="12" y1="5" x2="12" y2="19"></line>
              <line x1="5" y1="12" x2="19" y2="12"></line>
            </svg>
          </button>
          <div
            x-spread="collapse"
            x-cloak
            class="pb-5 pr-0 text-gray-700 md:pr-40"
            id="faq6"
          >
            Tratamento é qualquer tipo de manipulação realizada com dados
            pessoais, seja fisicamente ou virtualmente - como visualizar dados,
            compartilhá-los, coletá-los, arquivá-los, entre outros.
          </div>
        </div>
      </div>
    </section>

    <div class="text-center">
      <a
        href="/perguntas-frequentes"
        target="_blank"
        class="btn btn-light btn-lg btn-icon"
      >
        <span>Ver todas as perguntas frequentes</span>
        <svg viewBox="0 0 20 20" fill="currentColor" class="ml-3">
          <path
            fill-rule="evenodd"
            d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </a>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FAQET',
  data: () => ({
    open: false,
  }),
};
</script>

<style scoped></style>
