import axios from 'axios';
import configService from '@/services/configService';

export default {
  async getOneDocument({ id_user, id_document }) {
    return axios
      .get(
        `${configService.get(
          'apiUrl'
        )}/document/?id_user=${id_user}&id_document=${id_document}`
      )
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        throw e;
      });
  },
  async getAllDocuments({ id_user }) {
    return axios
      .get(`${configService.get('apiUrl')}/documents?id_user=${id_user}`)
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        throw e;
      });
  },
  async createDocument(data) {
    return axios
      .post(`${configService.get('apiUrl')}/document`, data)
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        throw e;
      });
  },
  async updateDocument(data) {
    return axios
      .put(`${configService.get('apiUrl')}/document`, data)
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        throw e;
      });
  },
  async updateStylesDocument(data) {
    return axios
      .put(`${configService.get('apiUrl')}/document/style`, data)
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        throw e;
      });
  },
  async getStylesFromDocument({ id_user, id_document }) {
    return axios
      .get(
        `${configService.get(
          'apiUrl'
        )}/document/style?id_user=${id_user}&id_document=${id_document}`
      )
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        throw e;
      });
  },
  async requestDownloadFile({ document_name, id_user, id_document }) {
    return axios
      .get(
        `${configService.get(
          'apiUrl'
        )}/document/donwload/${document_name}?id_user=${id_user}&id_document=${id_document}`,
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        throw e;
      });
  },
};
