<template>
  <div id="app">
    <alert
      :alertTitle="alertTitle"
      :alertText="alertText"
      :alertType="alertType"
      :alertPosition="alertPosition"
      v-if="showAlert"
    ></alert>
    <div>
      <template v-if="home === 'easyTermos'">
        <nav-bar-ET />
      </template>
      <template v-else-if="home === 'complianceOnline'">
        <nav-bar-c-o />
        <div class="py-4"></div>
      </template>
      <template v-else-if="home === 'modeloLGPD'">
        <nav-bar-m-l-g-p-d />
      </template>

      <div>
        <router-view class="min-h-screen" />
        <template v-if="home === 'easyTermos'">
          <footer-bar-e-t />
        </template>
        <template v-else-if="home === 'complianceOnline'">
          <footer-bar-c-o class="" />
        </template>
        <template v-else-if="home === 'modeloLGPD'">
          <footer-bar-m-l-g-p-d />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import NavBarET from './components/Partials/ET/NavBar.vue';
import FooterBarET from './components/Partials/ET/FooterBar.vue';
import NavBarCO from './components/Partials/CO/NavBar.vue';
import FooterBarCO from './components/Partials/CO/FooterBar.vue';
import Alert from './components/Alert';
import NavBarMLGPD from './components/Partials/MLGPD/NavBar.vue';
import FooterBarMLGPD from './components/Partials/MLGPD/FooterBar.vue';

import ConfigService from '@/services/configService';
import { mapGetters, mapState } from 'vuex';

export default {
  computed: {
    ...mapState('alert', [
      'showAlert',
      'alertType',
      'alertText',
      'alertTitle',
      'alertPosition',
    ]),
  },
  metaInfo() {
    const url = document.URL;
    let description;
    let favicon;

    if (url.includes('compliance')) {
      description = 'Documentos Jurídicos';
    } else if (url.includes('easy')) {
      description = 'Gerador de Termos de uso';
    }

    return {
      title: 'App',
      titleTemplate: '%s | ' + description,
      meta: [],
    };
  },
  components: {
    NavBarET,
    FooterBarET,
    Alert,
    NavBarCO,
    FooterBarCO,
    NavBarMLGPD,
    FooterBarMLGPD,
  },
  beforeCreate() {
    ConfigService.loadConfig();
  },
  mounted() {
    const url = document.URL;

    if (url.includes('compliance')) {
      this.home = 'complianceOnline';
    } else if (url.includes('easy')) {
      this.home = 'easyTermos';
    }
    process.env.VUE_APP_SYSTEM !== null && url.includes('local')
      ? (this.home = process.env.VUE_APP_SYSTEM)
      : '';
  },
  data: () => ({
    isOpen: false,
    home: '',
  }),
};
</script>

<style lang="scss">
@import './assets/css/custom.scss';
</style>
