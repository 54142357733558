<template>
  <section class="container mx-auto py-24 px-4" v-if="posts.length > 0">
    <h2 class="text-3xl text-gray-900 leading-tight mb-2 font-extrabold">
      Artigos sobre LGPD
    </h2>
    <p class="text-lg text-gray-600 mb-20">
      Confira nossos artigos, guias e anúncios para se manter atualizado com
      todas as leis e regulamentações de privacidade que afetam seus negócios.
    </p>
    <div
      class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-12"
    >
      <div v-for="(item, index) in posts.slice(0, 3)" v-bind:key="index">
        <a :href="'/artigos/' + item.seo_url">
          <img
            :src="
              'https://cms.easytermos.com.br' +
              item.foto_destaque.formats.large.url
            "
            class="object-cover bg-center h-56 w-full rounded mb-5"
            :alt="item.seo_url"
            loading="lazy"
          />
        </a>
        <h2 class="text-gray-900 text-lg font-semibold mb-2">
          <a
            :href="'/artigos/' + item.seo_url"
            class="text-gray-900 hover:text-purple-700"
            >{{ item.title }}</a
          >
        </h2>
        <p class="text-sm text-gray-600 font-normal mb-3">
          <vue-showdown
            class="blog-post"
            :markdown="item.post_text.slice(0, 250) + '…'"
            flavor="github"
            :options="{ emoji: true }"
          />
        </p>
        <p class="text-sm text-gray-600 font-normal mb-3">
          <a
            href="/blog"
            class="text-gray-900 hover:text-purple-700 font-medium"
            >{{ item.autor.Nome }}</a
          >
          • {{ item.data }}
        </p>
      </div>
    </div>
    <div
      class="flex items-center justify-center space-x-0 md:space-x-2 space-y-2 md:space-y-0 mt-20 flex-col md:flex-row"
    >
      <a
        class="flex mx-auto btn btn-light btn-lg inline-flex justify-center items-center w-full sm:w-auto mb-2 sm:mb-0"
        href="/blog"
      >
        Ver artigos
        <svg
          class="ml-1 w-4 h-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z"
            clip-rule="evenodd"
          />
        </svg>
      </a>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import router from '@/router';

export default {
  name: 'BlogCO',
  data() {
    return {
      home: false,
      blogPosts: [],
    };
  },
  created() {
    this.showPosts();
  },
  computed: {
    computed: {
      ...mapState('blog', ['posts']),
    },
  },
  methods: {
    ...mapActions('blog', ['getPosts']),
    async showPosts() {
      await this.getPosts({ sistema: 'ComplianceOnline', count: 0 });
    },
  },
};
</script>

<style scoped></style>
