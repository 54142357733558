import axios from 'axios';
import configService from '@/services/configService';

export default {
  async getPagina(sistema, id) {
    axios.defaults.headers.common['Authorization'] = '';
    return axios
      .get(`https://cms.easytermos.com.br/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        throw e;
      });
  },
};
