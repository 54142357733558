import _ from 'lodash';
import axios from 'axios';
import moment from 'dayjs';
import jwtDecode from 'jwt-decode';

import authService from '@/services/authService';
import documentService from "@/services/documentService";

const state = {
  authKey: localStorage.getItem('auth-key') || '',
  loading: false,
  isRegistered: false,
  isLoggedIn: false,
  isPasswordResetRequested: false,
  isPasswordResetted: false,
  user: localStorage.getItem('auth-key')
    ? jwtDecode(localStorage.getItem('auth-key'))
    : null,
};

const actions = {
  passwordReset({ dispatch, commit }, { key, password, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    authService
      .passwordReset({ key, password })
      .then((response) => {
        commit('passwordResetSuccess', {});
        dispatch(
          'alert/success',
          { showType: 'toast', title: response.message },
          { root: true }
        );
        this.modalLogin = false;
        this.modalLoginRegister = false;
        this.modalLoginRecover = false;
        router.push('/');
      })
      .catch((e) => {
        commit('passwordResetFailure');

        dispatch(
          'common/handleServiceException',
          { e, router },
          { root: true }
        );
      });
  },

  passwordResetRequest({ dispatch, commit }, { email, referer, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    authService
      .passwordResetRequest({ email, referer })
      .then((response) => {
        commit('passwordResetRequestSuccess', {});
        dispatch(
          'alert/success',
          { showType: 'toast', title: response.message },
          { root: true }
        );
        return true;
      })
      .catch((e) => {
        commit('passwordResetRequestFailure');

        dispatch(
          'common/handleServiceException',
          { e, router },
          { root: true }
        );
      });
  },
  register(
    { dispatch, commit },
    { username, email, password, firstName, lastName, referer, router }
  ) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    authService
      .register({ username, email, password, firstName, lastName, referer })
      .then((response) => {
        commit('registerSuccess', {});
        dispatch(
          'alert/success',
          { showType: 'toast', title: response.message },
          { root: true }
        );
        this.modalLogin = false;
        this.modalLoginRegister = false;
      })
      .catch((e) => {
        console.log(e);
        commit('registerFailure');

        dispatch(
          'common/handleServiceException',
          { e, router },
          { root: true }
        );
      });
  },
  registerEasy(
    { dispatch, commit },
    { email, document,id_document, callback }
  ) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    authService
    .registerEasy({ email })
    .then((response) => {
      commit('registerSuccess', {});
      commit('loginSuccess', { authKey: response.data.auth_key });

      documentService
      .createDocument({ id_user: response.data.id, document, id_document })
      .then(() => {
        callback();
      }).catch((err) => {
        console.log(err)
      })

    })
    .catch((e) => {
      dispatch(
        'alert/error',
        {
          showType: 'toast',
          title: 'Error',
          text:
            'Ops, ocorreu um erro ao criar seu documento, verifique se você já não possui esse documento.',
        },
        { root: true }
      );

      console.log(e);
    });
  },
  login(
    { dispatch, commit },
    { username, password, router, isCreatingDocument = false }
  ) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    authService
      .login(username, password)
      .then((response) => {
        commit('loginSuccess', { authKey: response.data.auth_key });
        dispatch(
          'alert/success',
          { showType: 'toast', title: response.message },
          { root: true }
        );
        this.modalLogin = false;
        this.modalLoginRegister = false;

        if (!isCreatingDocument) {
          router.push('/app');
        }

        dispatch(
          'modal/toggleIsCreatingDocument',
          {
            isCreatingDocument: false,
          },
          { root: true }
        );
      })
      .catch((e) => {
        commit('loginFailure');

        const {
          data: {
            data: [{ msg }],
          },
        } = e.response;

        dispatch(
          'alert/error',
          {
            showType: 'toast',
            title: 'Error',
            text: msg,
          },
          { root: true }
        );
      });
  },
  logout({ dispatch, commit }, { router, slient = false }) {
    dispatch('alert/clear', {}, { root: true });
    commit('logout');

    if (!slient) {
      dispatch(
        'alert/success',
        { showType: 'toast', title: 'Você saiu da sua conta com sucesso.' },
        { root: true }
      );

      router.push('/');
    }
  },
  sessionExpired({ dispatch, commit }, { router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('logout');

    dispatch(
      'alert/error',
      {
        showType: 'toast',
        title: 'Session expired',
        text: 'Please login with your account.',
      },
      { root: true }
    );

    router.push('/login');
  },
  handleAuthMessageKey({ dispatch }, { messageKey }) {
    switch (messageKey) {
      case 'registerConfirmSuccess':
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            title: 'Success',
            text:
              'Você está verificado. Por favor, entre com seu usuário e senha normalmente.',
          },
          { root: true }
        );
        break;
      case 'registerConfirmFailed':
        dispatch(
          'alert/error',
          {
            showType: 'toast',
            title: 'Failed',
            text:
              'Desculpa, nós tivemos um problema para verificar seu e-mail. Tente novamente.',
          },
          { root: true }
        );
        break;
      case 'registerConfirmValidationError':
        dispatch(
          'alert/error',
          {
            showType: 'toast',
            title: 'Failed',
            text: 'The token is expired or not valid. Please try again.',
          },
          { root: true }
        );
        break;
      default:
        break;
    }
  },
};

const getters = {
  isLoggedIn: (state) => () => {
    if (state.isLoggedIn) {
      return true;
    }

    if (_.isEmpty(state.authKey)) {
      return false;
    }

    let decoded = null;
    try {
      decoded = jwtDecode(state.authKey);
    } catch (e) {
      return false;
    }

    if (decoded.exp && moment.unix(decoded.exp).isAfter()) {
      axios.defaults.headers.common.Authorization = state.authKey;
      return true;
    }

    return false;
  },
  hasCookie: (state) => () => {
    if (state.hasCookie) {
      return true;
    } else {
      return false;
    }
  },
};

const mutations = {
  startRequest(state) {
    state.loading = true;
    state.isRegistered = false;
    state.isLoggedIn = false;
  },
  loginSuccess(state, { authKey }) {
    state.loading = false;
    state.isLoggedIn = true;
    state.authKey = authKey;
    state.user = jwtDecode(authKey);
    localStorage.setItem('auth-key', authKey);
    axios.defaults.headers.common.Authorization = authKey;
  },
  loginFailure(state) {
    state.loading = false;
  },
  registerSuccess(state) {
    state.loading = false;
    state.isRegistered = true;
  },
  registerFailure(state) {
    state.loading = false;
    state.isRegistered = false;
  },
  passwordResetRequestSuccess(state) {
    state.loading = false;
    state.isPasswordResetRequested = true;
  },
  passwordResetRequestFailure(state) {
    state.loading = false;
    state.isPasswordResetRequested = false;
  },
  passwordResetSuccess(state) {
    state.loading = false;
    state.isPasswordResetted = true;
  },
  passwordResetFailure(state) {
    state.loading = false;
    state.isPasswordResetted = false;
  },
  logout(state) {
    state.isLoggedIn = false;
    state.authKey = null;
    state.user = null;
    localStorage.removeItem('auth-key');
    axios.defaults.headers.common.Authorization = null;
  },
  clear(state) {
    state.loading = false;
    state.isRegistered = false;
    state.isLoggedIn = false;
    state.isPasswordResetRequested = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
