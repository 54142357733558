<template>
  <div>
    <div
      class="fixed bottom-0 border-2 border-et-500 text-sm right-0 rounded-lg shadow-lg p-4 bg-white md:mb-10 md:mr-10 text-gray-800"
      style="max-width: 500px; z-index: 1000"
      v-if="!hasCookies()"
      v-show="modalCookies"
    >
      O nosso website utiliza cookies para lhe identificar e aprimorar a sua
      experiência de navegação. Alguns cookies são necessários para autenticação
      e utilização da ferramenta, outros são utilitários, analíticos ou de
      marketing. Ao continuar navegando, você concorda com os termos da nossa
      <a href="/termos" class="underline">Política de Dados</a>.

      <button
        class="btn bg-et-500 text-white block mt-2"
        @click="
          setCookies();
          modalCookies = false;
        "
        style="float: right"
      >
        Aceitar e Prosseguir
      </button>
    </div>
    <div v-if="home === 'easyTermos'">
      <Hero class="pt-10"></Hero>
      <PricingTable></PricingTable>
      <ForAllCompanies />

      <Features></Features>

      <Blog></Blog>

      <FAQ></FAQ>
      <login-box-e-t
        v-if="isLoggedIn() === false && showModalLogin"
      ></login-box-e-t>
    </div>
    <div v-else-if="home === 'complianceOnline'">
      <div class="bg-gray-900">
        <hero-c-o></hero-c-o>
      </div>
      <features-c-o></features-c-o>
      <section class="container px-0 py-12 mx-auto sm:px-4">
        <div
          class="grid items-center grid-cols-1 gap-10 px-4 py-6 overflow-hidden text-white bg-co-500 border-pink-100 rounded-none card card-body sm:rounded-lg md:px-10 md:grid-cols-5 lg:gap-0"
        >
          <div class="col-span-1 md:col-span-3">
            <h2
              class="mb-3 font-serif text-2xl font-normal leading-tight lg:text-3xl"
            >
              Durma em paz sabendo que seu empresa está de acordo com a
              legislação.
            </h2>
            <p class="mb-6 text-sm font-semibold lg:text-base">
              Nossos documentos seguem todas as diretrizes pela LGPD, bem como
              as boas práticas de mercados nacionais e internacionais.
            </p>
            <a href="/app" class="w-full shadow btn btn-white btn-lg sm:w-auto"
              >Comece agora mesmo</a
            >
          </div>
        </div>
      </section>
      <content-c-o></content-c-o>

      <pricing-table-c-o id="pricing"></pricing-table-c-o>
      <blog-c-o></blog-c-o>
      <section class="container px-0 py-12 mx-auto sm:px-4">
        <div
          class="grid items-center grid-cols-1 gap-10 px-4 py-6 overflow-hidden text-white bg-co-500 border-pink-100 rounded-none card card-body sm:rounded-lg md:px-10 md:grid-cols-5 lg:gap-0"
        >
          <div class="col-span-1 md:col-span-3">
            <h2
              class="mb-3 font-serif text-2xl font-normal leading-tight lg:text-3xl"
            >
              Recursos que tornam o compliance simples.
            </h2>
            <p class="mb-6 text-sm font-semibold lg:text-base">
              Administrar uma empresa exige que você cumpra uma ampla variedade
              de leis, regras e diretrizes de provedores de serviços. É um
              aborrecimento e um risco tentar cumprir todos esses regulamentos
              por conta própria. Nosso serviço pode ajudar a aliviar o fardo da
              conformidade legal e dar a você tranquilidade.
            </p>
            <router-link
              tag="a"
              to="/app"
              class="w-full shadow btn btn-white btn-lg sm:w-auto"
            >
              Comece agora mesmo
            </router-link>
          </div>
        </div>
      </section>
      <faq-c-o></faq-c-o>
    </div>
  </div>
</template>

<script>
import Hero from '../components/Home/HomeET/Hero.vue';
import PricingTable from '../components/Home/HomeET/PricingTable.vue';
import Features from '../components/Home/HomeET/Features.vue';
import Blog from '../components/Blog.vue';
import BlogCO from '../components/Home/HomeCO/BlogCO.vue';
import FAQ from '../components/Home/HomeET/FAQ.vue';

import HeroCO from '../components/Home/HomeCO/Hero';
import FeaturesCO from '../components/Home/HomeCO/Features';
import PricingTableCO from '../components/Home/HomeCO/Pricing';
import FaqCO from '../components/Home/HomeCO/FAQ';
import { mapGetters, mapState } from 'vuex';
import ContentCO from '@/components/Home/HomeCO/ContentCO';

import LoginBoxET from '@/components/Home/HomeET/LoginBox.vue';
import ForAllCompanies from '@/views/ForAllCompanies';

export default {
  name: 'home',
  components: {
    ForAllCompanies,
    Hero,
    PricingTable,
    Features,
    Blog,
    HeroCO,
    FeaturesCO,
    FaqCO,
    PricingTableCO,
    ContentCO,
    LoginBoxET,
    BlogCO,
    FAQ,
  },
  data() {
    return {
      isOpen: false,
      home: '',
      modalCookies: true,
      publishableKey:
        'pk_test_51Hi67uCUIDjTGujWlcMwrZwW1nfIcYwhyhLqItv0T4UW3JuTG9DGAafwOrulq7LuUh6vLJJrBFItT0ii8mdp5Fok00LI1FPElZ',
      lineItems: [{ price: 'prod_IPS7fl8nMP4amk', quantity: 1 }],
      successUrl: 'https://teste.com',
      mode: 'payment',
      cancelUrl: 'https://teste.com',
    };
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
    ...mapState('modal', ['showModalLogin']),
  },
  created: function () {
    const url = document.URL;
    if (url.includes('compliance')) {
      this.home = 'complianceOnline';
    } else if (url.includes('easy')) {
      this.home = 'easyTermos';
    }
    process.env.VUE_APP_SYSTEM !== null && url.includes('local')
      ? (this.home = process.env.VUE_APP_SYSTEM)
      : '';
  },
  methods: {
    hasCookies() {
      if (localStorage.cookies === undefined) {
        return false;
      } else {
        return true;
      }
    },
    setCookies() {
      localStorage.cookies = true;
    },
    checkout() {
      this.$refs.checkoutRef.redirectToCheckout();
    },
  },
  metaInfo() {
    let title;
    let favicon;

    let content;
    const url = document.URL;
    if (url.includes('compliance')) {
      this.home = 'complianceOnline';
      title = 'Compliance Online';
      favicon = './img/icons-co.ico/favicon-16x16.png';

      content = 'Termos de uso e privacidade para adequação na LGPD';
    } else if (url.includes('easy')) {
      this.home = 'easyTermos';
      title =
        'Gerador de Política de Privacidade 🕵️‍ Gere Políticas de Privacidade GRATUITAS';
      favicon = './img/icons-easy.ico/favicon-16x16.png';

      content =
        'Gere documentos de Termos de Uso e Política de Privacidade para o seu site ou aplicativo sem custos. ';
    }
    process.env.VUE_APP_SYSTEM !== null && url.includes('local')
      ? (this.home = process.env.VUE_APP_SYSTEM)
      : '';

    return {
      link: [
        {
          rel: 'icon',
          href: favicon,
          type: 'image/png',
        },
      ],
      title: title,

      meta: [
        {
          name: 'description',
          content: content,
          icon: favicon,
        },
      ],
    };
  },
};
</script>
