<template>
  <section class="text-gray-700 body-font">
    <section class="container mx-auto pt-12 pb-12 px-12" id="pricing">
      <div class="w-full mx-auto">
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-16 sm:gap-8">
          <div
            style="background: rgb(253, 238, 176)"
            class="card shadow-none rounded-3xl cursor-pointer transition duration-150 transform hover:scale-105 border-0 md:border"
            @click="() => navigateToDocument({ id_plano: ID_BASIC_PLAN_EASY })"
          >
            <div>
              <p
                class="text-lg font-medium leading-3 text-center text-gray-900 mt-12"
              >
                Política Básica de Privacidade
              </p>
              <p class="text-sm leading-none text-center text-gray-700 mt-6">
                Política de Privacidade Simples
              </p>

              <p class="text-sm leading-none text-center text-gray-700 mt-2">
                Ideal para uso pessoal
              </p>
            </div>
            <div class="flex items-center justify-center" style="height: 200px">
              <img
                class="mx-auto"
                style="height: 180px; width: auto"
                src="/img/SVG/drawkit-grape-pack-illustration-1.svg"
                alt=""
              />
            </div>
            <transition name="">
              <div class="p-6 rounded-lg" v-show="!hideComparison">
                <ul
                  class="flex flex-col bg-white text-sm text-gray-700 rounded-lg shadow-sm flex-grow p-6 space-y-3"
                >
                  <li class="flex items-start">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      class="w-5 h-5 text-yellow mr-2"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="4"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    <span class=""> Política de privacidade </span>
                  </li>
                  <li class="flex items-start">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      class="w-5 h-5 text-yellow mr-2"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="4"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    <span class=""> Versão HTML </span>
                  </li>
                  <li class="flex items-start">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      class="w-5 h-5 text-yellow mr-2"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="4"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    <span class=""> Versão em Texto </span>
                  </li>
                  <li class="flex items-start">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      class="w-5 h-5 text-red-700 mr-2"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="4"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                    <span class=""> Termos de Uso </span>
                  </li>
                  <li class="flex items-start">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      class="w-5 h-5 text-red-700 mr-2"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="4"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                    <span class=""> Embed dos documentos </span>
                  </li>
                  <!--                  <li class="flex items-start">-->
                  <!--                    <svg-->
                  <!--                      xmlns="http://www.w3.org/2000/svg"-->
                  <!--                      fill="none"-->
                  <!--                      viewBox="0 0 24 24"-->
                  <!--                      class="w-5 h-5 text-red-700 mr-2"-->
                  <!--                      stroke="currentColor"-->
                  <!--                    >-->
                  <!--                      <path-->
                  <!--                        stroke-linecap="round"-->
                  <!--                        stroke-linejoin="round"-->
                  <!--                        stroke-width="4"-->
                  <!--                        d="M6 18L18 6M6 6l12 12"-->
                  <!--                      />-->
                  <!--                    </svg>-->
                  <!--                    <span class=""> Política de Cookies </span>-->
                  <!--                  </li>-->
                  <li class="flex items-start">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      class="w-5 h-5 text-red-700 mr-2"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="4"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                    <span class=""> Preparado para a LGPD </span>
                  </li>
                  <li class="flex items-start">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      class="w-5 h-5 text-red-700 mr-2"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="4"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                    <span class=""> Edição visual de documentos </span>
                  </li>
                  <li class="flex items-start">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      class="w-5 h-5 text-red-700 mr-2"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="4"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                    <span class=""> Planilha de registro e operações </span>
                  </li>
                  <li class="flex items-start">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      class="w-5 h-5 text-red-700 mr-2"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="4"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                    <span class=""> Relatório de impacto </span>
                  </li>
                  <li class="flex items-start">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      class="w-5 h-5 text-red-700 mr-2"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="4"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                    <span class=""> Guia de utilização das planilhas </span>
                  </li>
                </ul>
              </div>
            </transition>
            <div
              class="px-8 font-medium text-sm py-3 mb-2 bg-white rounded-full mx-auto"
            >
              Escolher - Gratuito
            </div>
          </div>
          <div
            style="background: rgb(223, 235, 253)"
            class="card shadow-none rounded-3xl cursor-pointer transition duration-150 transform hover:scale-105 border-0 md:border"
            @click="
              () => navigateToDocument({ id_plano: ID_COMPLETE_PLAN_EASY })
            "
          >
            <div>
              <p
                class="text-lg font-medium leading-3 text-center text-gray-900 mt-12"
              >
                Pacote LGPD Completo
              </p>
              <p class="text-sm leading-none text-center text-gray-700 mt-6">
                Política de Privacidade e
              </p>
              <p class="text-sm leading-none text-center text-gray-700 mt-2">
                Termos de uso
              </p>
            </div>
            <div class="flex items-center justify-center" style="height: 200px">
              <img
                class="mx-auto"
                style="height: 150px; width: auto"
                src="/img/SVG/drawkit-grape-pack-illustration-11.svg"
                alt=""
              />
            </div>
            <transition name="">
              <div class="p-6 rounded-lg" v-show="!hideComparison">
                <ul
                  class="flex flex-col bg-white text-sm text-gray-700 rounded-lg shadow-sm flex-grow p-6 space-y-3"
                >
                  <li class="flex items-start">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      class="w-5 h-5 text-blue mr-2"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="4"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    <span class=""> Política de privacidade </span>
                  </li>
                  <li class="flex items-start">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      class="w-5 h-5 text-blue mr-2"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="4"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    <span class=""> Versão HTML </span>
                  </li>
                  <li class="flex items-start">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      class="w-5 h-5 text-blue mr-2"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="4"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    <span class=""> Versão em Texto </span>
                  </li>
                  <li class="flex items-start">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      class="w-5 h-5 text-blue mr-2"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="4"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    <span class=""> Embed dos documentos </span>
                  </li>
                  <li class="flex items-start">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      class="w-5 h-5 text-blue mr-2"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="4"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    <span class=""> Termos de Uso </span>
                  </li>
                  <!--                  <li class="flex items-start">-->
                  <!--                    <span class="w-5 h-5 text-pink mr-2"> 🍪 </span>-->

                  <!--                    <span class=""> Política de Cookies </span>-->
                  <!--                  </li>-->
                  <li class="flex items-start">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      class="w-5 h-5 text-blue mr-2"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="4"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    <span class=""> Preparado para a LGPD </span>
                  </li>
                  <li class="flex items-start">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      class="w-5 h-5 text-red-700 mr-2"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="4"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                    <span class=""> Edição visual de documentos </span>
                  </li>
                  <li class="flex items-start">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      class="w-5 h-5 text-red-700 mr-2"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="4"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                    <span class=""> Planilha de registro e operações </span>
                  </li>
                  <li class="flex items-start">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      class="w-5 h-5 text-red-700 mr-2"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="4"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                    <span class=""> Relatório de impacto </span>
                  </li>
                  <li class="flex items-start">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      class="w-5 h-5 text-red-700 mr-2"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="4"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                    <span class=""> Guia de utilização das planilhas </span>
                  </li>
                </ul>
              </div>
            </transition>

            <div
              class="px-8 font-medium text-sm py-3 mb-8 bg-white rounded-full mx-auto"
            >
              Escolher - R$ 99.00
            </div>
          </div>
          <div
            style="background: rgb(223, 114, 157)"
            class="card text-white shadow-none rounded-3xl cursor-pointer transition duration-150 transform hover:scale-110 border-0 md:border"
            @click="
              () => navigateToDocument({ id_plano: ID_ADVANCED_PLAN_EASY })
            "
          >
            <p
              class="absolute p-2 py-1 text-sm badge font-regular rounded-sm text-gray-700 bg-white"
              style="
                border: 1px solid rgb(223, 114, 157);
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, -50%);
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                class="w-5 h-5 text-blue-500 mr-1"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="4"
                  d="M5 13l4 4L19 7"
                />
              </svg>
              Mais escolhido
            </p>
            <div>
              <p class="text-lg font-medium leading-3 text-center mt-12">
                Pacote Avançado
              </p>
              <p class="text-sm leading-none text-center mt-6">
                Política abrangente de privacidade personalizada,
              </p>

              <p class="text-sm leading-none text-center mt-2">
                termos, cookies e relatórios internos de compliance
              </p>
            </div>
            <div class="flex items-center justify-center" style="height: 200px">
              <!--              <img-->
              <!--                class="mx-auto"-->
              <!--                style="height: 160px; width: auto"-->
              <!--                src="/img/SVG/drawkit-grape-pack-illustration-2.svg"-->
              <!--                alt=""-->
              <!--              />-->
              <lottie-animation
                path="img/testa.json"
                class="hidden md:block"
                :speed="1"
                style="height: 230px; width: 230px"
              />
            </div>
            <transition name="">
              <div class="p-6 rounded-lg" v-show="!hideComparison">
                <ul
                  class="flex flex-col bg-white text-sm text-gray-700 rounded-lg shadow-sm flex-grow p-6 space-y-3"
                >
                  <li class="flex items-start">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      class="w-5 h-5 text-pink mr-2"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="4"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    <span class=""> Política de privacidade </span>
                  </li>
                  <li class="flex items-start">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      class="w-5 h-5 text-pink mr-2"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="4"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    <span class=""> Versão HTML </span>
                  </li>
                  <li class="flex items-start">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      class="w-5 h-5 text-pink mr-2"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="4"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    <span class=""> Versão em Texto </span>
                  </li>
                  <li class="flex items-start">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      class="w-5 h-5 text-pink mr-2"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="4"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    <span class=""> Termos de Uso </span>
                  </li>
                  <li class="flex items-start">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      class="w-5 h-5 text-pink mr-2"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="4"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    <span class=""> Embed dos documentos </span>
                  </li>
                  <!--                  <li class="flex items-start">-->
                  <!--                    <span class="w-5 h-5 text-pink mr-2"> 🍪 </span>-->
                  <!--                    <span class=""> Política de Cookies </span>-->
                  <!--                  </li>-->
                  <li class="flex items-start">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      class="w-5 h-5 text-pink mr-2"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="4"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    <span class=""> Preparado para a LGPD </span>
                  </li>
                  <li class="flex items-start">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      class="w-5 h-5 text-pink mr-2"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="4"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    <span class=""> Edição visual de documentos </span>
                  </li>
                  <li class="flex items-start">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      class="w-5 h-5 text-pink mr-2"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="4"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    <span class=""> Planilha de registro e operações </span>
                  </li>
                  <li class="flex items-start">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      class="w-5 h-5 text-pink mr-2"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="4"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    <span class=""> Relatório de impacto </span>
                  </li>
                  <li class="flex items-start">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      class="w-5 h-5 text-pink mr-2"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="4"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    <span class=""> Guia de utilização das planilhas </span>
                  </li>
                </ul>
              </div>
            </transition>
            <div
              class="px-8 text-gray-700 font-medium text-sm py-3 mb-8 bg-white rounded-full mx-auto"
            >
              Escolher - R$ 125.00
            </div>
          </div>
        </div>
        <div
          class="px-8 my-16 cursor-pointer hover:bg-gray-200 transition duration-150 border-1 border-gray-700 font-medium text-sm py-3 bg-white rounded-full mx-auto max-w-sm text-center mb-8"
          style="border: 1px solid; width: 200px"
          @click="hideComparison = !hideComparison"
        >
          <template v-if="hideComparison"> Mostrar detalhes </template>
          <template v-else> Esconder detalhes </template>
        </div>
      </div>
    </section>
  </section>
</template>
<script>
import {
  ID_ADVANCED_PLAN_EASY,
  ID_BASIC_PLAN_EASY,
  ID_COMPLETE_PLAN_EASY,
} from '@/utils/variables.json';
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'; // import lottie-vuejs

export default {
  name: 'PricingTable',
  status: 'prototype',
  release: '1.0.0',
  components: {
    LottieAnimation,
  },
  props: [''],
  mounted() {},
  data() {
    return {
      hideComparison: false,
      ID_ADVANCED_PLAN_EASY,
      ID_BASIC_PLAN_EASY,
      ID_COMPLETE_PLAN_EASY,
    };
  },
  methods: {
    navigateToDocument({ id_plano }) {
      this.$router.push(`/document/${id_plano}/politicas-privacidade/MQ==`);
    },
  },
};
</script>

<style lang="scss">
.ribbon {
  position: absolute;
  top: 0;
  right: 0;
  width: 200px;
  text-align: center;
  height: 25px;
  margin-right: -40px;
  margin-top: 30px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Safari prior 9.0 */
  transform: rotate(45deg); /* Standard syntax */
}

@media (max-width: 600px) {
  .ribbon {
    display: none !important;
  }
}

.text-blue {
  color: rgb(64, 125, 212);
}

.text-pink {
  color: rgb(223, 114, 157);
}

.text-yellow {
  color: rgb(253, 238, 176);
}
</style>
