import Vue from 'vue';
import VueMeta from 'vue-meta';
import Vuelidate from 'vuelidate';

import App from './App.vue';
import router from './router';
import store from './store';
import VueGtag from 'vue-gtag';
import VueShowdown from 'vue-showdown';
import VueContentPlaceholders from 'vue-content-placeholders';
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'; // import lottie-vuejs

Vue.use(LottieAnimation); // add lottie-animation to your global scope

Vue.use(VueContentPlaceholders);
// the second parameter of Vue.use() is optional

Vue.use(VueShowdown, {
  // set default flavor of showdown
  flavor: 'github',
  // set default options of showdown (will override the flavor options)
  options: {
    emoji: true,
  },
});
Vue.use(
  VueGtag,
  {
    config: { id: 'G-4232YFD697' },
    includes: [{ id: 'G-G0LZ93SDDS' }],
  },
  router
);

Vue.config.productionTip = false;

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});
Vue.use(Vuelidate);

// Politicas Privacidade
Vue.component('politicas-privacidade', () =>
  import('./components/Documents/PoliticaPrivacidade')
);
Vue.component('politicas-privacidade-form', () =>
  import('./components/DocumentsForm/FormPoliticaPrivacidade')
);
Vue.component('politicas-privacidade-preview', () =>
  import('./components/Documents/PoliticaPrivacidadePreview')
);

// Termos de Uso
Vue.component('termos-de-uso', () =>
  import('./components/Documents/TermosDeUso')
);
Vue.component('termos-de-uso-form', () =>
  import('./components/DocumentsForm/FormTermosDeUso')
);
Vue.component('termos-de-uso-preview', () =>
  import('./components/Documents/TermosDeUsoPreview')
);

// Politicas Cookies
// Termos de Uso
Vue.component('politicas-cookies', () =>
  import('./components/Documents/PoliticaCookies')
);
Vue.component('politicas-cookies-form', () =>
  import('./components/DocumentsForm/FormPoliticaCookies')
);
Vue.component('termos-de-uso-preview', () =>
  import('./components/Documents/TermosDeUsoPreview')
);

Vue.component('v-style', {
  render: function (createElement) {
    return createElement('style', this.$slots.default);
  },
});
Vue.component('powered-by', () =>
  import('./components/PoweredBy/PoweredBy.vue')
);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
